import React, { Component } from "react";

import welcomeblog_banner from "../assets/img/home/welcomeblog_banner.svg";
import welcome_blogimg1 from "../assets/img/home/welcome_blogimg1.svg";
import welcome_blogimg2 from "../assets/img/home/welcome_blogimg2.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import uxblogbanner1 from "../assets/img/home/uxblogbanner1.svg";
import back_arrow from "../assets/img/home/back_arrow.svg";
import designEyesblog1 from "../assets/img/home/designEyesblog1.png";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class helloSummary extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner pb-0">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-12 col-xs-12 px-0">
                <div class="main-txt innerBlog_txt">
                  <div>
                    <img src={welcomeblog_banner} alt="blog_banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <Link to="/resources">
                    {" "}
                    <p>
                      <img src={back_arrow} className="mr-2"></img>Back
                    </p>
                  </Link>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  <h4>JUNE 25, 2020 | AUTHOR TRULY DESIGN FIRM</h4>
                  <figure class="d-block d-sm-block d-md-none">
                    <h2>
                      Hello! Why Don’t We Start with A Brief Introduction of
                      your Company?
                    </h2>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h2>
                      Hello! Why Don’t We Start with A <br></br>Brief
                      Introduction of your Company?
                    </h2>
                  </figure>
                </div>
                <div class="mission_paras">
                  <p>
                    👋 Hello! Truly is a Branding and Product{" "}
                    <span className="text-uppercase">UX/UI</span> Design Studio
                    that helps businesses accomplish their objectives. Keeping
                    simple, intelligent design as the prime focus, we help build
                    the brand of our clients from the basic steps to ensuring
                    the best User Experience for our clients’ customers.{" "}
                  </p>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      That’s great! Can you please Elaborate More on your
                      Services?{" "}
                    </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Sure, we provide complete Brand design and User Experience
                      solutions for businesses. We lay a strong foundation for
                      the business through branding ensuring that it stands
                      unique - right from identity to packaging. Our services in
                      branding include designing the brand logo, Branding guide,
                      formulating brand strategy, Brand stationery design and
                      Print Media design.
                    </p>
                    <p>
                      Coming to our User Experience services, we strive to craft
                      enjoyable experiences that solve real problems and aptly
                      fit goals. We Start with research and laying out strategy
                      and proceed through our User experience Design, User
                      Interface Design, Visual & Web design. All of this ensures
                      that your foundation in the digital world is responsive,
                      seamless and intuitive.
                    </p>
                    <p>
                      Everything is appropriately tested too. We believe
                      pitfalls and climbing up as our best guides! We do
                      extensive A/B testing along with prototypes to understand
                      what’s best for our clients. We deliver web designs that
                      engage your audience and enable optimized interaction for
                      our client’s specific business.
                    </p>
                    <p>
                      That’s not the end of it though, We offer product design
                      services providing product strategy, defining the scope of
                      the product, prototype design, Visual design, constructing
                      the app all related to the digital product. We also help
                      businesses in customer acquisition, providing great
                      customer experience and retaining their customers!
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <img
                    src={welcome_blogimg1}
                    alt="img"
                    className="welcome_img1"
                  />
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      Great Outcomes Come from Great Processes Right.. What is
                      the Core Process Behind your Work?{" "}
                    </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      That is true. Our branding process starts with
                      understanding and trying to convey the story of our
                      client. We go beyond requirements. We ask questions, a lot
                      of them. We dive into our client’s culture, their models
                      and business. We know our clients are with us because … we
                      strive to give what our clients are looking for and more.
                      <br></br>
                      From there through positioning your brand as relatable in
                      the customers’ minds, setting the correct tone of voice
                      and putting everything together, we provide a visual
                      identity.
                    </p>
                    <p>
                      Next, for User experience, we start with detailed market
                      research providing us with many insights, then delve into
                      process architecture, visual and interaction designs
                      ending with perfect user experience for products in the
                      beta/shipped/released stage.
                    </p>
                    <p>
                      Our custom design process starts with a briefing, carrying
                      onto the processes of researching, ideation, presentation
                      & revisions. Once we get everything right and ready to go
                      live, we start executing the ideas, deliver it as expected
                      to you and publish them!
                    </p>
                    <p>
                      All of this work is greatly helped by our use of different
                      tools including Adobe Photoshop, Illustrator, XD and
                      others.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <img
                    src={welcome_blogimg2}
                    alt="img"
                    className="welcome_img1"
                  />
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      What Helped your Company Grow from its Infancy to What it
                      is Now?{" "}
                    </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Definitely our people and our best relations with the
                      clients. Our people are full of curiosity, positive
                      mindset and entrepreneurial attitude that enable us to
                      deliver the best quality product for our clients.
                    </p>
                    <p>
                      Our core values are being accountable, striving for
                      excellence, never settling in the same spot, growing
                      together with the team, being adventurous, being
                      limitless, staying positive and always passionate towards
                      our work. These make sure that we are always oriented to
                      the right path and achieve the common goal of ours and our
                      clients — creating memorable user experiences!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission branding-sec pt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>RECENT BLOGS</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0 about_col">
                <div class="row blog_row">
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/conversational-uxdesign">
                      <div class="card">
                        <div class="card-body">
                          <img src={uxblogbanner1} alt="blog_image" />
                          <h5>Enhancing the User Experience of Chatbots</h5>
                          <p>
                            The Role of User Experience is indispensable in the
                            success of any application. All the great features
                            of your product would not be “great” if they are not
                            usable!
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/design-that-speaks-eyes">
                      <div class="card">
                        <div class="card-body">
                          <img src={designEyesblog1} alt="blog_image" />
                          <h5>Design that Speaks to the Eyes</h5>
                          <p>
                            Design is truly a visual voice that speaks to the
                            eyes… that communicates your brand to your
                            consumers.{" "}
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default helloSummary;
