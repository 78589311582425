import React, { Component } from "react";

import welcomeBlog1 from "../assets/img/home/welcomeBlog1.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import uxblogbanner1 from "../assets/img/home/uxblogbanner1.svg";
import designspeaksimg2 from "../assets/img/home/designspeaksimg2.svg";
import back_arrow from "../assets/img/home/back_arrow.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import designEyeblog2 from "../assets/img/home/designEyeblog2.png";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class design_that_speaks_eyes extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner pb-0">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-12 col-xs-12 px-0">
                <div class="main-txt innerBlog_txt">
                  <div>
                    <img src={designEyeblog2} alt="blog_banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <Link to="/resources">
                    {" "}
                    <p>
                      <img src={back_arrow} className="mr-2"></img>Back
                    </p>
                  </Link>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  <h4>AUGEST 06, 2020 | AUTHOR TRULY DESIGN FIRM</h4>
                  <h2>Design That Speaks To The Eyes </h2>
                  {/* <h2>Brief Introduction Of Your Company?</h2> */}
                </div>
                <div class="mission_paras">
                  <p>
                    Design is truly a visual voice that speaks to the eyes… that
                    communicates your brand to your consumers. A study by Adobe
                    shows that over a span of 10 years, companies with strong
                    design outperform companies with weak design by 219% on the
                    S&P index which readily tells how important design is!{" "}
                  </p>
                  <p>
                    While everyone agrees that great design is important, no one
                    agrees on the specifics of design.
                  </p>
                  <p>
                    However, there are certain things that are universally
                    accepted as the qualities of a great design.
                  </p>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      However, there are certain things that are universally
                      accepted as the qualities of a great design.
                    </h2>
                  </div>
                  <div class="mission_paras">
                    <p>Design That Makes The Great First Impression</p>
                    <p>
                      With hundreds of thousands of brands competing for the
                      attention of your consumer daily, any business needs to
                      hook its consumers on the first impression itself. Many
                      studies have shown that it takes just 50 milliseconds to
                      assess a visual appeal!{" "}
                    </p>
                    <p>
                      Figuring out what key things you want to communicate to
                      the consumer at the start and how to tell those details
                      helps you create a great design that makes a great first
                      and lasting impression.
                    </p>
                  </div>
                </div>

                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Design That Creates Trust </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Before any of your consumers starts a business with you
                      and entrusts you to help them, they assess the risks. Thus
                      It is important to build trust in business through design.
                      Incorporating testimonials, showing your team, your values
                      are some of the ways you can build this trust!
                    </p>
                    <p>
                      Also, a design that creates trust needs to be a
                      long-lasting one. It takes many impressions of a logo to
                      register into the minds of people and if you change it
                      often, people may intuitively feel your brand as
                      untrustable.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Design That Communicates Value </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Every user will have a purpose: be it aesthetic/pleasure
                      or material/professional. The primary objective of your
                      business is to understand the needs of your customers and
                      provide solutions for their problems… that is how you come
                      into the context of your users and provide them value.
                      Often, your prospective customers do not even know they
                      have a problem or can articulate them. Great design
                      communicates the value that your business provides in a
                      way they understand!
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Design That Inspires To Take Action </h2>
                  </div>
                  <div className="mission_paras">
                    <p>
                      Once the user gets trust in your businesses and is
                      convinced of the value it provides they may have a little
                      bit of hesitation for further action… be it slight
                      confusion of where to go, needing further information, or
                      just lack of sense of urgency. Aptly called Call To
                      Action, it is the message you give that informs your
                      customers of the next steps they need to take.
                    </p>
                    <p>
                      {" "}
                      <img
                        src={designspeaksimg2}
                        alt="img"
                        className="welcome_img1"
                      />
                    </p>
                    <p>
                      Including CTA eliminates much of the confusion and
                      provides a direction and possibly a sense of urgency for
                      the user to go fulfill their needs!
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Final Words </h2>
                  </div>
                  <div className="mission_paras">
                    <p>
                      The above things are just the tip of the iceberg… There
                      are many other principles and concepts that tell what
                      great designs are and how they should be. As I mentioned
                      at the start, there is no specific rule set or guide for
                      telling exactly how to design things which could be
                      incredibly frustrating for beginners. But, this helps to
                      remove the hegemony of a few ideas and lets everyone
                      pursue their own path of great design!{" "}
                    </p>
                    <p>
                      After working hard creatively communicating things and
                      solving problems through design, the result would be
                      highly satisfactory and you can contribute to the
                      ever-expanding ideas of design!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission branding-sec pt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>RECENT BLOGS</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0 about_col">
                <div class="row blog_row">
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/helloSummary">
                      <div class="card">
                        <div class="card-body">
                          <img src={welcomeBlog1} alt="blog_image" />
                          <h5>
                            Hello! Why don’t we start with a brief introduction
                            of your company?
                          </h5>
                          <p>
                            Hello!<br></br> Truly is a branding and Product{" "}
                            <span className="text-uppercase">UX/UI</span> Design
                            Studio that helps businesses accomplish their
                            objectives.
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/conversational-uxdesign">
                      <div class="card">
                        <div class="card-body">
                          <img src={uxblogbanner1} alt="blog_image" />
                          <h5>Enhancing the User Experience of Chatbots</h5>
                          <p>
                            The Role of User Experience is indispensable in the
                            success of any application. All the great features
                            of your product would not be “great” if they are not
                            usable!
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default design_that_speaks_eyes;
