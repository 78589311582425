import React, { Component } from 'react';

import washhit_big from '../assets/img/home/washhit_big.png';
import wash_small from '../assets/img/home/wash_small.svg';
import arrow_black from '../assets/img/home/arrow_black.svg';
import  work_img1  from '../assets/img/home/work_img1.png';
import  work_img2  from '../assets/img/home/work_img2.png';
import behance_logo from '../assets/img/home/behance_logo.svg';
import youtube from '../assets/img/home/youtube.svg';
import facebook from '../assets/img/home/facebook.svg';
import instagram from '../assets/img/home/instagram.svg';
import washhit_prod1 from '../assets/img/home/washhit_prod1.png';
import { HashLink as Link } from 'react-router-hash-link';
import * as $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



class washhit extends Component {


    componentDidMount() {




    }

    render() {



        return (
            <div className="animate-sec">
                {/* banner starts */}
                <section class="banner">
                    <div class="container banner_container">
                        <div class="row">
                        <div class="col-lg-4 col-md-4 col-xs-12">
                                <div class="banner_text project_bnr_text">
                                  <div>
                                  <h1>Client
                                     </h1>
                                    <h1 className="project_bnr_head">Washh it</h1>
                                  </div>
                                  <div className="project_to_head">
                                  <h1>Domain
                                     </h1>
                                    <h1 className="project_bnr_head">Dry Cleaning</h1>
                                  </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-xs-12">
                               <img src={washhit_big} alt="image" className="washhit_big"/>
                            </div>
                        </div>
                    </div>

                </section>

                {/* banner ends */}
                <section class="mission">
<div class="container">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
    <div class="section-header">
     <p>INTRODUCTION</p>
    </div>
    </div>
    <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
    <div class="section-header ">
    <h4>Services Provided</h4>
      <h2>Branding & identity | website design  </h2>
    
     <h2>| publicity | product UX design |</h2>
     <h2>business strategy</h2>
    </div>
   
   <div class="mission_paras">
   <div className="mt-3">
   <OwlCarousel items={1} dots={true} className="owl-theme product_images project_images washhit_prod_img" loop margin={40} > 
        <div>
        <img src={washhit_prod1}/>
        </div>
        <div>
        <img src={washhit_prod1}/>
        </div>
        <div>
        <img src={washhit_prod1}/>
        </div>
         </OwlCarousel>
      
   </div>
   </div>
<div className="about_paras">
<div class="section-header ">
      <h2>Design challenge </h2>
    </div>
   <div class="mission_paras">
     <p>Washh it approached us with the crux of their idea — of providing the best dry cleaning service. At that time, they had no website and Brand identity and more sophisticated Business strategy. </p>
     <p>We had to do what is the most important for Businesses in this digital era — to build a brand identity and establish a strong online presence for them and to devise and implement strategies for spreading the word out. </p>
     <p className="mb-0"> After a lot of discussion and reviews, we formed up the key things to do:</p>
     <ul className="prod_ul">
         <li>Build a consistent Brand identity in all spaces  </li>
         <li>Improve the Customer Experience with the product</li>
         <li>Build robust web design that converts visitors into customers</li>
     </ul>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>Our solution </h2>
    </div>
   <div class="mission_paras">
     <p>After methodically understanding what Washh it want to say to the customers and what the customer want, we started the designing process that lay the digital foundation for Washh it. The core decisions we made </p>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>Focus on the key message </h2>
    </div>
   <div class="mission_paras">
     <p>In the Website Design, users would first see what matters the most — that they are about to get the best dry cleaning service. Not just information though, we included all the things the user instantly needs to do — various contact information and calls to action. </p>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>A Design that goes easy  </h2>
      <h2>on eyes and is responsive</h2>
    </div>
   <div class="mission_paras">
     <p>We do not want to clutter the screen with a lot of design elements. We Design the page so that it goes easy on the eyes of the visitor and can guide it well. The Website is also technically made sure that the website changes the layout based on the User’s configuration. </p>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>Brand identity that builds connection </h2>
    </div>
   <div class="mission_paras">
     <p>We want a Brand that people remember. The first step in establishing that is the logo. We stuck to a logo that is simple yet aesthetically appealing. Then we developed visual components that are consistent and speak the same design language. Overall, we gave the brand its identity whose components form a coherent whole. </p>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>Effective Online Business Strategy </h2>
    </div>
   <div class="mission_paras">
     <p>We understood the entire business space of dry cleaning and formulated strategies for growing online. We encouraged Washh it to publish blogs which is a great way of getting in touch with customers. Also, we have laid out the strategy of advertising and marketing. </p>
     </div>
</div>
<div className="about_paras">
<div class="section-header ">
      <h2>Result: </h2>
    </div>
   <div class="mission_paras">
     <p>The Result turned out to be quite great. We have developed a strong brand identity for Washh it and developed their business strategy along with great product UX. </p>
     <p>Traffic saw a boost in many fold and along them, conversion rates got a boost too. When we surveyed visitors, 97% of them told that they really liked the website design and 81% of them were recurring customers!</p>
     </div>
</div>
<div className="row work_row customer-row project_rows project_rows1">
        
<div className="col-lg-4 col-md-4 col-xs-12">
        <div className="card">
        <OwlCarousel items={1} dots={true} className="owl-theme product_images project_images" loop margin={40} > 
        <div>
        <img src={wash_small}/>
        </div>
        <div>
        <img src={wash_small}/>
        </div>
        <div>
        <img src={wash_small}/>
        </div>
         </OwlCarousel>
      
        
           </div>
        </div>
       
        <div className="col-lg-8 col-md-8 col-xs-12">
        <div className="card">
        <OwlCarousel items={1} dots={true} className="owl-theme product_images project_images" loop margin={40} > 
        <div>
        <img src={work_img2}/>
        </div>
        <div>
        <img src={work_img2}/>
        </div>
        <div>
        <img src={work_img2}/>
        </div>
         </OwlCarousel>
        
           </div>
        </div>
      
          </div>
</div>

</div>
</div></section>
                <section class="mission hiring" id="hiredesigner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                                <div class="section-header hiring_header text_white">
                                    <p>Let’s talk!</p>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                            <figure class="d-block d-sm-block d-md-none">
        <div class="section-header hiring_header mt-3">
          <h2>Let’s start a new project together   </h2>
        
        </div>
    </figure>
    <figure class="d-none d-sm-none d-md-block">
    <div class="section-header hiring_header">
          <h2>Let’s start a new project    </h2>
          <h2>together </h2>
         
        </div>
     </figure>
                                <div class="mission_paras hiring_para contact_para">
                                    <p className="mb-0">Feel free to ask us about our team, technology, process and we’re happy to answer all your questions.</p>
                                    <div className="d-flex all_btns">
                                         <Link to="/contact"><button class="primary_btn">get in touch<img src={arrow_black} className=
                                            "arrow" /></button></Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                           </div>
        );
    }
}

export default washhit;
