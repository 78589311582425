import React, { Component } from "react";

import about_bnr from "../assets/img/home/about_bnr.svg";
import uxblogbanner1 from "../assets/img/home/uxblogbanner1.svg";
import welcomeBlog1 from "../assets/img/home/welcomeBlog1.svg";
import designEyesblog1 from "../assets/img/home/designEyesblog1.png";
import arrow_black from "../assets/img/home/arrow_black.svg";

import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";

class resources extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
           <Helmet >
          <title>Mobile App UX Design in India | Truly</title>
          <meta name="title" content="Mobile App UX Design in India | Truly" />
          <meta
            name="description"
            content="Truly promises to provide you the high-level UI/UX design services in India, on-time delivery, and complete support at an affordable price. Best Designing Company in India."
          />
           <meta property="og:url" content="https://www.trulydesignfirm.com/resources"/>
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12"></div>
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <h1>resources</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>
                      Learn all about the How, Why, What and When of Design
                    </h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Learn all about the How, <br></br>Why, What and When of
                      Design
                    </h3>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Blog</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0 about_col">
                <div class="row blog_row">
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/helloSummary">
                      <div class="card">
                        <div class="card-body">
                          <img src={welcomeBlog1} alt="blog_image" />
                          <h5>
                            Hello! Why don’t we Start with a Brief Introduction
                            of Your Company?
                          </h5>
                          <p>
                            Hello!<br></br> Truly is a branding and Product{" "}
                            <span className="text-uppercase">UX/UI</span> Design
                            Studio that helps businesses accomplish their
                            objectives.
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/conversational-uxdesign">
                      <div class="card">
                        <div class="card-body">
                          <img src={uxblogbanner1} alt="blog_image" />
                          <h5>Enhancing the User Experience of Chatbots</h5>
                          <p>
                            The Role of User Experience is indispensable in the
                            success of any application. All the great features
                            of your product would not be “great” if they are not
                            usable!
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/design-that-speaks-eyes">
                      <div class="card">
                        <div class="card-body">
                          <img src={designEyesblog1} alt="blog_image" />
                          <h5>Design that Speaks to the Eyes</h5>
                          <p>
                            Design is truly a visual voice that speaks to the
                            eyes… that communicates your brand to your
                            consumers.{" "}
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                      target="_blank"
                    >
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" />
                      </button>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default resources;
