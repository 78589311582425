import React, { Component } from "react";

import manifesto_bnr from "../assets/img/home/manifesto_bnr.svg";
import manifestoimg2 from "../assets/img/home/manifestoimg2.svg";
import manifestoimg1 from "../assets/img/home/manifestoimg1.svg";
import manifestoimg3 from "../assets/img/home/manifestoimg3.svg";
import manifestoimg4 from "../assets/img/home/manifestoimg4.svg";
import manifestoimg5 from "../assets/img/home/manifestoimg5.svg";
import manifestoimg6 from "../assets/img/home/manifestoimg6.svg";

import arrow_black from "../assets/img/home/arrow_black.svg";

import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class manifesto extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
               
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img
                    src={manifesto_bnr}
                    class="expert-bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                <div className="d-flex align-items-end justify-content-between">
                <div class="banner_text banner_mob_text">
                  <h1>TRULY manifesto</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Flexible, but Sticking to our Principles</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Flexible, but Sticking <br></br>to our Principles
                    </h3>
                  </figure>
                  {/* <Link to="/contact"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="arrow" />
                    </button>
                    </a>
                  {/* </Link> */}
                  {/* <Link to='/contact'><button class="primary_btn bnr_btn">contact us<img src={arrow_black} className=
      "arrow" alt="image"/></button></Link> */}
                </div>
                <figure class="d-none d-sm-none d-md-block">
                  <img src={manifesto_bnr} class="expert-bnr all_bnr_img" alt="image"/>
                </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Manifesto</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="expertise-inner">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <img
                        src={manifestoimg1}
                        class="e1-img expert-images img-fluid"
                        alt="manifesto-images"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <div class="expert-left">
                        <div class="section-header  team-header">
                          <h2>
                            We Ask. We Learn.<br></br> We Implement
                          </h2>
                        </div>

                        <p>
                          We don’t just take requirements and start working on
                          them. But, we ask questions, learn clients’ cultures,
                          models and business. Most of all, we actually listen
                          and ensure you get what you are looking for, and more.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row manifesto-row">
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <figure class="d-block d-sm-block d-md-none">
                        {" "}
                        <img
                          src={manifestoimg2}
                          class="e1-img expert-images img-fluid"
                          alt="manifesto-images"
                        />{" "}
                      </figure>
                      <div class="expert-left">
                        <div class="section-header  team-header">
                          <h2>
                            Our Focus Includes <br></br>Last Mile Users
                          </h2>
                        </div>

                        <p>
                          We work mainly for your customers as they are your
                          prime goal. Our experience as a design agency in
                          Hyderabad tells us that it’s the best strategy to
                          implement.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <figure class="d-none d-sm-none d-md-block">
                        {" "}
                        <img
                          src={manifestoimg2}
                          class="e1-img expert-images img-fluid"
                          alt="manifesto-images"
                        />
                      </figure>
                    </div>
                  </div>
                  <div class="row manifesto-row">
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <img
                        src={manifestoimg3}
                        class="e1-img expert-images img-fluid"
                        alt="manifesto-images"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <div class="expert-left">
                        <div class="section-header  team-header">
                          <h2>
                            {" "}
                            Prototype First <br></br>&{" "}
                            <span className="text-uppercase">A/B</span> Testing
                          </h2>
                        </div>

                        <p>
                          Pitfalls and climbing back up has been our best guide
                          so far. Hence, we build prototypes first and also
                          include A/B testing for the best outcomes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row manifesto-row">
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <figure class="d-block d-sm-block d-md-none">
                        {" "}
                        <img
                          src={manifestoimg4}
                          class="e1-img expert-images img-fluid"
                          alt="manifesto-images"
                        />{" "}
                      </figure>
                      <div class="expert-left">
                        <div class="section-header team-header">
                          <h2> We Show you A Different Perspective</h2>
                        </div>

                        <p>
                          We know that you are with us because there is
                          something that we thought of which you haven’t.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <figure class="d-none d-sm-none d-md-block">
                        {" "}
                        <img
                          src={manifestoimg4}
                          class="e1-img expert-images img-fluid"
                          alt="manifesto-images"
                        />
                      </figure>
                    </div>
                  </div>
                  <div class="row manifesto-row">
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <img
                        src={manifestoimg5}
                        class="e1-img expert-images img-fluid"
                        alt="manifesto-images"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <div class="expert-left">
                        <div class="section-header team-header">
                          <h2> Our Clients Love Us for What and How We Do</h2>
                        </div>

                        <p>
                          One doesn’t simply like something unless they do. We
                          value and respect our clients and their thoughts.
                          Hence, they know that they have hired the best design
                          agency for their business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row manifesto-row">
                    <figure class="d-block d-sm-block d-md-none">
                      {" "}
                      <img
                        src={manifestoimg6}
                        class="e1-img expert-images img-fluid"
                        alt="manifesto-images"
                      />
                    </figure>
                    <div class="col-lg-8 col-md-8 col-xs-12 px-0">
                      <div class="expert-left">
                        <div class="section-header team-header">
                          <h2> Sweet Reaps</h2>
                        </div>

                        <p>
                          The combination of our skills, efficiency, creativity
                          and the expertise of the team are what we offer. Our
                          successful clients reward us for these.
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12 px-0">
                      <figure class="d-none d-sm-none d-md-block">
                        {" "}
                        <img
                          src={manifestoimg6}
                          class="e1-img expert-images img-fluid"
                          alt="manifesto-images"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default manifesto;
