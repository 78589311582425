import React, { Component } from "react";

import about_bnr from "../assets/img/home/about_bnr.svg";
import apple from "../assets/img/home/apple.png";
import arrow_black from "../assets/img/home/arrow_black.svg";
import q from "../assets/img/home/q.svg";
import what_we_do_bnr from "../assets/img/home/what_we_do_bnr.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";

class what_we_do extends Component {
  state = {
    responsive: {
      0: {
        // items: 1,
        // stagePadding:0,
        // navText: [
        //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        //   '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        // ],
      },
      // 450: {
      //     items: 1,
      // },
      // 600: {
      //     items: 1,
      // },
      1000: {
        items: 2.8,
        autoplay: true,
        slideTransition: "linear",
        autoplaySpeed: 3000,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
      },
    },
    // autoHeight : true,
    // transitionStyle:"fade"
  };

  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
          <Helmet >
          <title> Top UX Companies in India | Truly</title>
          <meta name="title" content=" Top UX Companies in India | Truly" />
          <meta
            name="description"
            content="Being one of the top UX companies in India, Truly are constantly committed to creating next-gen UI/UX and branding solutions for businesses."
          />
           <meta property="og:url" content="https://www.trulydesignfirm.com/what-we-do"/>
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
               
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img
                    src={what_we_do_bnr}
                    class="expert-bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                <div className="d-flex align-items-end justify-content-between">
                <div class="banner_text banner_mob_text">
                  <h1>TRULY SERVICES</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Truly, Setting your Brand Apart</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Truly, Setting your <br></br> Brand Apart
                    </h3>
                  </figure>
                  {/* <Link to="/contact"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="arrow" />
                    </button>
                    </a>
                  {/* </Link> */}
                  {/* <Link to='/contact'><button class="primary_btn bnr_btn">contact us<img src={arrow_black} className=
      "arrow" alt="image"/></button></Link> */}
                </div>
                <figure class="d-none d-sm-none d-md-block">
                  <img src={what_we_do_bnr} class="expert-bnr all_bnr_img" alt="image"/>
                </figure>
                </div>
                
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}

        <section class="mission expertise-cards branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>BRANDING</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>Laying a Strong Foundation for your Business - Brand</h2>
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Brand <br></br>Logo Design{" "}
                        </h5>
                        <p>
                          Your logo is what helps users to quickly associate
                          with your business. Creating logos that shape your
                          brand’s story, core values, and beliefs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Branding <br></br>Guide{" "}
                        </h5>
                        <p>
                          How you present your brand to the world is
                          quintessential. From the design system to defining
                          components and design elements, everything holds equal
                          importance. A Brand Guide helps throughout your
                          journey.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Brand <br></br> Strategy{" "}
                        </h5>
                        <p>
                          Brand Strategy is the key to bridging the gap between
                          how your brand is perceived and how you want your
                          brand to be perceived by the world.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Branding <br></br> Stationery Design{" "}
                        </h5>
                        <p>
                          Your brand’s stationary handouts play an important
                          role. It helps users remember you and also enhances
                          the message you want to convey.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Print <br></br> Media Design{" "}
                        </h5>
                        <p>
                          A physical entity, say, a brochure holds as much
                          importance as a digital entity holds. They communicate
                          your brand to customers even in your absence while
                          also gaining you important ones.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-empty"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mission ux-sec expertise-cards">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    <span className="text-uppercase">UI/UX</span> DESIGN
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>
                    Your Website is your Business’ Foundation in the Digital
                    World.
                  </h2>
                  {/* <h2 className="mt-2"> We ensure it’s responsive, unique, intuitive, and seamless.</h2> */}
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Strategy & <br></br>Research{" "}
                        </h5>
                        <p>
                          Laying out a strategy that ensures reaching all the
                          touchpoints where people interact by performing
                          intensive research.
                        </p>
                        <ul>
                          <li>1. Requirement Analysis</li>
                          <li>2. Project Goals</li>
                          <li>3. Competitor Analysis</li>
                          <li> 4. User Needs Focus Groups</li>
                          <li> 5. Market Research</li>
                          <li>6. Heuristic Analysis</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          User Experience <br></br>Design{" "}
                        </h5>
                        <p>
                          Tailoring a design that achieves the goals, values,
                          and products of the website whilst promoting certain
                          behaviors.
                        </p>
                        <ul>
                          <li>1. Problem Statement</li>
                          <li>2. Personas</li>
                          <li>3. User Journey</li>
                          <li> 4. Information Architecture </li>
                          <li>5. Content Audit</li>
                          <li>6. Low & High Fidelity Wireframes</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          User Interface <br></br>Design{" "}
                        </h5>
                        <p>
                          Creating a successful User Interface that is easily
                          readable with best aesthetics and attention to detail.
                        </p>
                        <ul>
                          <li>1. Design System</li>
                          <li>2. Illustrations</li>
                          <li>3. Visual Interface Design </li>
                          <li>4. Web Design Mockups</li>
                          <li>5. A/B Testing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row expertise-row2 apple_row">
                  <div className="col-lg-4 col-md-6 col-xs-12 pl-0">
                    <div class="card expert-card2 ">
                      <div class="card-body">
                        <div class="experts-txt2">
                          <h5>
                            Visual & <br></br> Web Design{" "}
                          </h5>
                          <p>
                            Creating designs that plant trust in users through
                            enhancement of content and functions of the page.
                          </p>
                          <ul>
                            <li>
                              1. <span className="text-uppercase">UI</span>{" "}
                              Elements
                            </li>
                            <li>2. Visual Language</li>
                            <li>3. Layouts</li>
                            <li>4. Responsive Design</li>
                            <li>5. Web Optimization </li>
                            <li>6. Visual Hierarchy</li>
                            <li>7. Data Visualization</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-xs-12 pr-0 apple_col">
                    <div class="card expert-card2 apple_card">
                      <div class="card-body1 ">
                        <div class="experts-txt2 p-0">
                          <img src={q} className="q" />
                          <img src={apple} className="apple_img" />
                          <h5>
                            Design is not just what it Looks Like and Feels
                            Like.{" "}
                          </h5>
                          <h5> Design is how it Works.</h5>
                          <p>–Steve jobs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission expertise-cards branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    PRODUCT <br></br>DESIGN
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>We Make it Happen for you</h2>
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Product <br></br>Strategy{" "}
                        </h5>
                        <p>
                          Working together with the stakeholders on your team to
                          create the product blueprint and a roadmap.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Scope of <br></br>Product{" "}
                        </h5>
                        <p>
                          Defining the scope of the design which talks about
                          problems, critical touchpoints, features, goals, and
                          solutions for all.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          <span className="text-uppercase">UX</span> <br></br>{" "}
                          Construct{" "}
                        </h5>
                        <p>
                          Building the app by solving all the problems,
                          prioritizing features, and providing a framework that
                          allows iterative development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Prototype <br></br> Design{" "}
                        </h5>
                        <p>
                          One constructed, producing the prototype of the
                          application and making necessary developments by beta
                          testing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Visual <br></br> Design{" "}
                        </h5>
                        <p>
                          Putting together the right colors, fonts, designs, and
                          other relevant graphics in a systematic manner for
                          best results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Print <br></br> Media Design{" "}
                        </h5>
                        <p>
                          A physical entity, say, a brochure holds as much
                          importance as a digital entity holds. They communicate
                          your brand to customers even in your absence while
                          also gaining you important ones.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Acquisition{" "}
                        </h5>
                        <p>
                          Gathering a relevant audience base for your business
                          through various channels by incorporating a
                          systematic, sustainable and adaptable strategy.
                        </p>
                        <ul>
                          <li>1. Brand</li>
                          <li>2. Marketing</li>
                          <li>3. Communication </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Experience{" "}
                        </h5>
                        <p>
                          Building the best experience for your end-users
                          through design and functionality by solving critical
                          problems and reaching all touchpoints.
                        </p>
                        <ul>
                          <li>1. User Research</li>
                          <li>2. Product / Services Definition</li>
                          <li> 3. User Experience Design</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Retention{" "}
                        </h5>
                        <p>
                          Retaining customers is more challenging than gaining
                          new customers. Hence, we ensure that your customers
                          are retained.
                        </p>
                        <ul>
                          <li> 1. Customer Relationship</li>
                          <li>2. Loyalty Program</li>
                          <li>3. Design Retention Features </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default what_we_do;
