import React, { Component } from "react";

import testimonial_bnr from "../assets/img/home/testimonial_bnr.svg";
import cust1 from "../assets/img/home/cust1.png";
import cust2 from "../assets/img/home/cust2.png";
import cust3 from "../assets/img/home/cust3.png";
import arrow_black from "../assets/img/home/arrow_black.svg";
import cust4 from "../assets/img/home/cust4.png";
import cust5 from "../assets/img/home/cust5.png";
import cust6 from "../assets/img/home/cust6.png";
import quote from "../assets/img/home/quote.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class clients extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                </div>
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <h1>clients</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>
                      Behind each Creative Design, is our Ethic that Adds Value
                    </h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Behind each Creative Design, is our Ethic that Adds Value
                    </h3>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>clients</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header team-header">
                  <h2>Laying a Strong Foundation for your Business - Brand</h2>
                </div>
                <div class="testimonial-row">
                  <div class="row testimonial-cards allclients">
                    <div class="column">
                      <div class="card" id="cl_1">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Nuacem AI</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_2">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>TresmLabs AI</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_3">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Gopgo</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | Product{" "}
                              <span className="text-uppercase">UI</span>,{" "}
                              <span className="text-uppercase">UX</span> |
                              Website Design
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_4">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Starfire Jewellery AU</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | jewellery thoughts | printed
                              collateral
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_5">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Qurioseaty</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_6">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Walk n Travelapp</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="card" id="cl_7">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Nextergo</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Product{" "}
                              <span className="text-uppercase">UI</span>,{" "}
                              <span className="text-uppercase">UX</span> |
                              Website Design{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_8">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Body Workout</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">Branding & identity </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_9">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Washh it</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | Product{" "}
                              <span className="text-uppercase">UI</span>,{" "}
                              <span className="text-uppercase">UX</span> |
                              Website Design
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_10">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Happy Eagle Pest Control</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | stationery design| stationery
                              design
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_11">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Nuclyo</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_12">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Diet Bowl</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              {" "}
                              Branding & identity | Publicity and Advertising
                              Poster Designing
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="card" id="cl_13">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>ZOC Productions</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_14">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Merrito Polymers</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | stationery design
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_15">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>IDEEO Tech Solutions</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding & identity | Publicity and Advertising
                              Poster Designing | Product{" "}
                              <span className="text-uppercase">UI</span>,{" "}
                              <span className="text-uppercase">UX</span> |
                              Website Design
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_16">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>ANZ Cleaning Services AU</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_17">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Loyolaacademy</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card" id="cl_18">
                        <div class="card-body">
                          <div class="card-head">
                            <h6>Give Hope - Foundation</h6>
                          </div>
                          <div class="card-text">
                            <p class="testmonial-txt">
                              Branding and{" "}
                              <span className="text-uppercase">UI/UX</span>{" "}
                              design for Joe’s new mobile app and ordering
                              experience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br> Together{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default clients;
