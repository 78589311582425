import React, { Component } from "react";

import about_bnr from "../assets/img/home/about_bnr.svg";
import accountability from "../assets/img/home/accountability.svg";
import excellence from "../assets/img/home/excellence.svg";
import settle from "../assets/img/home/settle.svg";
import grow from "../assets/img/home/grow.svg";
import adventurous from "../assets/img/home/adventurous.svg";
import ideas from "../assets/img/home/ideas.svg";
import positive from "../assets/img/home/positive.svg";
import passion from "../assets/img/home/passion.svg";
import about_linkidin from "../assets/img/home/about_linkidin.svg";
import team_members from "../assets/img/home/team_members.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import sreedhar from "../assets/img/about/sreedhar.png";
import sravani from "../assets/img/about/sravani.png";
import lead from "../assets/img/about/lead.png";
import arjun from "../assets/img/about/arjun.png";
import virat from "../assets/img/about/virat.png";
import bharat from "../assets/img/about/bharat.png";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class about extends Component {
  state = {
    responsive: {
      0: {
        // items: 1,
        // stagePadding:0,
        // navText: [
        //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        //   '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        // ],
      },
      // 450: {
      //     items: 1,
      // },
      // 600: {
      //     items: 1,
      // },
      1000: {
        items: 2.8,
        autoplay: true,
        slideTransition: "linear",
        autoplaySpeed: 3000,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
      },
    },
    // autoHeight : true,
    // transitionStyle:"fade"
  };

  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
         <Helmet >
          <title>Branding and Website Design in India | Truly</title>
          <meta name="title" content="Branding and Website Design in India | Truly" />
          <meta
            name="description"
            content="Truly is a professional company for Branding and Website Design in India. Choose an organization that is trusted by brands for their UI/UX requirements in India."
          />
           <meta property="og:url" content="https://www.trulydesignfirm.com/about"/>
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
               
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  <img
                    src={about_bnr}
                    class="expert-bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                <div className="d-flex align-items-end justify-content-between">
                <div class="banner_text banner_mob_text">
                  <h1>WELCOME TO TRULY</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Bringing Imaginations to Digital Life</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Bringing Imaginations <br></br> to Digital Life
                    </h3>
                  </figure>

                  {/* <Link to="/contact"> */}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                    target="_blank"
                  >
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="arrow" />
                    </button>
                  </a>
                  {/* </Link> */}
                </div>
                <figure class="d-none d-sm-none d-md-block">
                  <img
                    src={about_bnr}
                    class="expert-bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">{/* <p>INTRODUCTION</p> */}</div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  {/* <h2>Meet our Team </h2> */}
                </div>

                <div class="mission_paras">
                  <p>
                    What started as a one-man army is now built to be a great
                    team. Looking back, we are proud to have overcome our
                    challenges, hurdles that led us to where we are now among
                    the best <span className="text-uppercase">UX</span> design
                    companies in Hyderabad.{" "}
                  </p>
                  <p>
                    With an aim to create a long-lasting impact through designs,
                    Truly is your one-stop designing company for branding, logo
                    designing, <span className="text-uppercase">UI/UX</span>,
                    product designing, mobile application designing and website
                    designing. Our end-to-end design solutions promise your
                    business the outcomes you expect.
                  </p>
                  <p>
                    Truly believes in having long-term relationships rather than
                    one-off projects and just meeting deliverables. Building
                    connections with our clients in order to reach their
                    business objectives and goals is our prime goal.
                  </p>

                  <p>
                    Our efficient team, creative minds, technology and appetite
                    for quality enables us to deliver the best and unique ideas
                    within the time frame. Also, the challenge of creating the
                    best experience for the mass minds out there keeps us going.
                  </p>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>Curious Minds </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Our curiosity is what makes us observe and learn from
                      everything around us. Implementing these learnings and
                      observations in the most unique ways for our clients is
                      what we attempt to do.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>Positive Thinkers </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      We keep the compliments close and keep the criticisms
                      closer. Our positive mind towards everything adds to the
                      fun like at our workplace.{" "}
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>Enrepreneurial Attitude </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      The challenges and constraints of today’s fast-moving
                      market are something that comes when you own an agency.
                      Our strong ambition and result-oriented attitude to
                      finding solutions are what keeps our attitude
                      entrepreneurial.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="courses what-we" id="courses">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header">
                  <p>CORE VALUES </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header learn_head hiring_header mt-3">
                    <div>
                      <h2>We Help Businesses Get their Designs Right</h2>
                    </div>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header learn_head hiring_header ">
                    <div>
                      <h2>
                        We Help Businesses <br></br> Get their Designs Right
                      </h2>
                    </div>
                  </div>
                </figure>

                <div className="">
                  <div class="about-values section_gap_top">
                    {/* data-touch="true" */}
                    {/* <div  class="carousel slide" data-ride="carousel" id="carouselExampleCaptions"  data-interval="2000"> */}
                    {/* <ol class="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="5"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="6"></li>
           <li data-target="#carouselExampleCaptions" data-slide-to="7"></li>
        </ol> */}
                    <OwlCarousel
                      items={1}
                      className="owl-theme course_mob_dots about_values_car"
                      loop
                      margin={20}
                      dots={true}
                    >
                      {/* <div class="carousel-inner"> */}
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>01</h2>
                                  <h4>Accountability</h4>
                                  <p>
                                    We take our responsibilities very seriously
                                    and ensure every step is taken with utmost
                                    certainty
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12 slider-img">
                            <div class="slider-img">
                              <img src={accountability} alt="accountability" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>02</h2>
                                  <h4>Excellence</h4>
                                  <p>
                                    Doing what is not easy has made us pioneers
                                    in what we do
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12 ">
                            <div class="slider-img">
                              <img src={excellence} alt="excellence" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>03</h2>
                                  <h4>Never Settle</h4>
                                  <p>
                                    A yearning for learning and growth keeps us
                                    on our feet to reach success
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={settle} alt="settle" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>04</h2>
                                  <h4>Grow Together</h4>
                                  <p>
                                    A team that grows together by overcoming
                                    challenges is the team that succeeds
                                    together
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={grow} alt="grow" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>05</h2>
                                  <h4>Be Adventurous</h4>
                                  <p>
                                    Taking on new challenges, setting new
                                    benchmarks and facing them head-on
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={adventurous} alt="adventurous" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>06</h2>
                                  <h4>Ideas Without Limits</h4>
                                  <p>
                                    Taking action on our ideas has made us
                                    discover endless possibilities
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={ideas} alt="ideas" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>07</h2>
                                  <h4>Stay Positive</h4>
                                  <p>
                                    Overcoming hurdles and keeping a positive
                                    mindset with every setback
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={positive} alt="positive" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row">
                          <div class="col-lg-5 col-md-5 col-xs-12">
                            <div class="slider-text">
                              <h5> CORE VALUES</h5>
                              <div class="card">
                                <div class="card-body">
                                  <h2>08</h2>
                                  <h4>Passion</h4>
                                  <p>We do what we love and love what we do</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="slider-img">
                              <img src={passion} alt="passion" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </OwlCarousel>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>MEET THE TEAM</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header team-header">
                  <h2>
                    Meet the Backbone of Truly We are Privileged to have them on
                    our Team
                  </h2>
                </div>
                <div class="mission_paras">
                  <p>
                    Reviews from our happy clients are what drives us to give
                    our best{" "}
                  </p>
                </div>

                <div class="row team-row">
                  <div class="col-lg-4 col-md-4 col-xs-12 px-0 ">
                    <div class="card team-card ">
                      <div class="card-body">
                        <img
                          src={lead}
                          class="img-fluid team_heads team_head1"
                          alt="lion"
                        />
                        <div class="team-name">
                          <div class="team-name1">
                            <div class="">
                              <h6>Simba</h6>
                              <p>Power of true</p>
                            </div>

                            <a
                              href="https://www.linkedin.com/in/simbatruly/"
                              target="_blank"
                            >
                              <img src={about_linkidin} alt="linkedin" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-4 col-xs-12  px-0">
                    <div class="card team-card ">
                      <div class="card-body">
                        <img
                          src={sreedhar}
                          class="img-fluid team_heads team_head1"
                          alt="truly-team-sreedhar"
                        />
                        <div class="team-name">
                          <div class="team-name1">
                            <div class="">
                              <h6>Sreedhar Truly</h6>
                              <p>Founder</p>
                            </div>

                            <a
                              href="https://www.linkedin.com/in/sreedhar-uxdesigner/"
                              target="_blank"
                            >
                              <img src={about_linkidin} alt="linkedin" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 col-xs-12 px-0">
                  <div class="card team-card">
                      <div class="card-body">
                        <img
                          src={sravani}
                          class="img-fluid team_members team_heads"
                          alt="truly-team-udaya"
                        />
                        <div class="team-name">
                          <h6>Sravani K</h6>
                          <p>
                            <span className="text-uppercase">UI</span> Developer
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div class="card team-card">
                      <div class="card-body">
                        <img
                          src={arjun}
                          class="img-fluid team_members team_heads"
                          alt="truly-team-udaya"
                        />
                        <div class="team-name">
                          <h6>Arjun</h6>
                          <p>Tech lead</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div class="row team-row team-row1">
                  <div class="col-lg-4 col-md-6 col-xs-12 px-0">
                  <div class="card team-card">
                      <div class="card-body">
                        <img
                          src={bharat}
                          class="img-fluid team_members team_heads"
                          alt="truly-team-udaya"
                        />
                        <div class="team-name">
                          <h6>Bharat Kumar</h6>
                          <p>
                            Lead <span className="text-uppercase">UX</span>{" "}
                            Designer
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12 px-0">
                   
                  </div>
                  <div class="col-lg-4 col-md-6 col-xs-12 px-0">
                    {/* <div class="card team-card">
                      <div class="card-body">
                        <img
                          src={virat}
                          class="img-fluid team_members team_heads"
                          alt="truly-team-udaya"
                        />
                        <div class="team-name">
                          <h6>Vijay Virat</h6>
                          <p>Marketing Head</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Big Ideas, Creative People, New Technology. </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Big Ideas, Creative People, <br></br>New Technology.{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                      target="_blank"
                    >
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default about;
