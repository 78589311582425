import React, { Component } from "react";

import apple from "../assets/img/home/apple.png";
import arrow_black from "../assets/img/home/arrow_black.svg";
import q from "../assets/img/home/q.svg";
import branding_process_img from "../assets/img/home/branding_process_img.svg";
import ux_process_img from "../assets/img/home/ux_process_img.svg";
// import arrow_black from '../assets/img/home/arrow_black.svg';
import Ditebowl1 from "../assets/img/works/Ditebowl1.png";
import Ditebowl from "../assets/img/works/Ditebowl.png";
import Washhit1 from "../assets/img/works/Washhit1.png";
import Washhit2 from "../assets/img/works/Washhit2.png";
import ux_path from "../assets/img/home/ux_path.svg";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class ux_design extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        <Helmet>
          <title>
            {" "}
            The Best SaaS Websites UI/UX DesignServices | trulydesignfirm.com{" "}
          </title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Affordable SaaS website UI/UX DesignServices focused on designing the right online experience for your Enterprises online leads and sales."
          />
          <meta
            name="keywords"
            content="saas website design, saas website design services, saas website uiux design, saas website uiux services, saas graphic design, saasuiux graphic design, saasuiux graphic design services, saas website design, saasux design, saas product design"
          />
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12"></div>
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <h1>
                    <span className="text-uppercase">UX</span> Design
                  </h1>
                  <h3>
                    Enterprise Product{" "}
                    <span className="text-uppercase">UX</span> Design Services
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>INTRODUCTION</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div class="mission_paras">
                  <p>
                    The product could be complex, the design doesn’t have to be.
                    Users are the primary object of most businesses and
                    services. Hence, user experience is the most important
                    aspect. <span className="text-uppercase">UX</span> is all
                    about making the product as simple as can be for the user.
                    It’s about enabling them to have a seamless experience with
                    your product. Consistency in design, seamless flows, and
                    usefulness of the products is what make a great{" "}
                    <span className="text-uppercase">UX</span>. Our{" "}
                    <span className="text-uppercase">UX</span> design services
                    ensure your product is at its best version before release.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* banner ends */}
        <section class="mission expertise-cards branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    <span className="text-uppercase">UX</span> Design<br></br>{" "}
                    Services
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>
                    Your website is your business’ foundation in the digital
                    world.
                  </h2>
                  {/* <h2 className="mt-2"> We ensure it’s responsive, unique, intuitive, and seamless.</h2> */}
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Strategy & <br></br>Research{" "}
                        </h5>
                        <p>
                          Laying out a strategy that ensures reaching all the
                          touchpoints where people interact by performing
                          intensive research.
                        </p>
                        <ul>
                          <li>1. Requirement Analysis</li>
                          <li>2. Project Goals</li>
                          <li>3. Competitor Analysis</li>
                          <li> 4. User Needs Focus Groups</li>
                          <li> 5. Market Research</li>
                          <li>6. Heuristic Analysis</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          User Experience <br></br>Design{" "}
                        </h5>
                        <p>
                          Tailoring a design that achieves the goals, values,
                          and products of the website whilst promoting certain
                          behaviors.
                        </p>
                        <ul>
                          <li>1. Problem Statement</li>
                          <li>2. Personas</li>
                          <li>3. User Journey</li>
                          <li> 4. Information Architecture </li>
                          <li>5. Content Audit</li>
                          <li>6. Low & High Fidelity Wireframes</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          User Interface <br></br> Design{" "}
                        </h5>
                        <p>
                          Creating a successful User Interface that is easily
                          readable with best aesthetics and attention to detail.
                        </p>
                        <ul>
                          <li>1. Design System</li>
                          <li>2. Illustrations</li>
                          <li>3. Visual Interface Design </li>
                          <li>4. Web Design Mockups</li>
                          <li>5. A/B Testing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row expertise-row2 apple_row">
                  <div className="col-lg-4 col-md-6 col-xs-12 pl-0">
                    <div class="card expert-card2 ">
                      <div class="card-body">
                        <div class="experts-txt2">
                          <h5>
                            Visual & <br></br> Web Design{" "}
                          </h5>
                          <p>
                            Creating designs that plant trust in users through
                            enhancement of content and functions of the page.
                          </p>
                          <ul>
                            <li>
                              1. <span className="text-uppercase">UI</span>{" "}
                              Elements
                            </li>
                            <li>2. Visual Language</li>
                            <li>3. Layouts</li>
                            <li>4. Responsive Design</li>
                            <li>5. Web Optimization </li>
                            <li>6. Visual Hierarchy</li>
                            <li>7. Data Visualization</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-xs-12 pr-0 apple_col">
                    <div class="card expert-card2 apple_card">
                      <div class="card-body1 ">
                        <div class="experts-txt2 p-0">
                          <img src={q} className="q" />
                          <img src={apple} className="apple_img" />
                          <h5>
                            Design is not just what it looks Like and feels
                            like. <br></br> Design is how it works.
                          </h5>
                          <p>–Steve jobs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="innerpagebranding">
          {/* ux */}
          <section class="mission pb-0">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                  <div class="section-header">
                    <p>
                      <span className="text-uppercase">UX</span> Process{" "}
                    </p>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                  <figure class="d-block d-sm-block d-md-none">
                    <div class="section-header mt-3">
                      <h2>
                        “Good Design is About Process, Not Product” - Jared
                        Sinclair{" "}
                      </h2>
                    </div>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <div class="section-header">
                      <h2>
                        “Good Design is About Process, <br></br>Not Product” -
                        Jared Sinclair{" "}
                      </h2>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section class="mission pt-0">
            <div className="container">
              <div class="row branding-row mt-0 ux_row">
                <div class="col-lg-6 col-md-12 col-xs-12">
                  <figure class="d-none d-sm-none d-md-block">
                    <img
                      src={ux_process_img}
                      class="branding-process-img"
                      alt="ux-process-image.svg"
                    />
                  </figure>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 pl-0">
                  <ul class="pl-0">
                    <li>
                      <h5>Release, Shipped Product, Beta Designs</h5>
                      <p>
                        Gaining insights into the{" "}
                        <span className="text-uppercase">UX</span> of your
                        product along with a deeper understanding of your
                        business goals through beta testing to test if your
                        goals align with the needs of the customers.
                      </p>
                    </li>
                    <li>
                      <h5>Visual Design, Interaction Design</h5>
                      <p>
                        Designing the interface and paying attention to detail
                        by keeping the best interest of users’ in mind while
                        also leaving room for improvisations. All this achieved
                        by taking into consideration different principles,
                        colors, properties, and other design variables.
                      </p>
                    </li>
                    <li>
                      <h5>Process Architecture</h5>
                      <p>
                        Developing a visual blueprint of the product based on
                        the business’ goals and hierarchy. Including the levels
                        of details in the information architecture as per
                        requirements.
                      </p>
                    </li>
                    <li>
                      <h5>Research + Strategy & Conceptual Design</h5>
                      <p>
                        Starting with thorough market research on the product
                        followed by building a strategy that best works for your
                        brand. Next, marking broad outlines of function and form
                        such as designing interactions, processes, and
                        experiences which are in line with the strategy.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section class="learn_things " id="shop">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-3">
                <div class="section-header">
                  <p class="">Our Works</p>
                </div>
              </div>

              <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
                <div className="row work_row customer-row project_rows">
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Ditebowl}
                            className="img-fluid"
                            alt="dietbowl"
                          />
                        </div>
                        <div>
                          <img
                            src={Ditebowl1}
                            className="img-fluid"
                            alt="dietbowl"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>diet bowl</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Diet bowl provides pure organic Cow Ghee that are
                            useful for various purposes. Our{" "}
                            <span className="text-uppercase">UI UX</span> Design
                            agency covered all the aspects of Branding &
                            Identity and designing posters.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Washhit1}
                            className="img-fluid"
                            alt="washhit"
                          />
                        </div>
                        <div>
                          <img
                            src={Washhit2}
                            className="img-fluid"
                            alt="washhit"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>washhit</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Washh it provides comprehensive dry cleaning &
                            laundry services including pickup and delivery times
                            that suit their customers. We provided complete
                            design services for them.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Big Ideas, Creative People, New Technology. </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Big Ideas, Creative People, <br></br>New Technology.{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ux_design;
