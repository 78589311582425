import React, { Component } from "react";

import arrow_black from "../assets/img/home/arrow_black.svg";
import branding_process_img from "../assets/img/home/branding_process_img.svg";
import ux_process_img from "../assets/img/home/ux_process_img.svg";
import Adtru from "../assets/img/works/Adtru.png";
import Adtru1 from "../assets/img/works/Adtru1.png";
import Df1 from "../assets/img/works/Df1.png";
import Df2 from "../assets/img/works/Df2.png";
// import arrow_black from '../assets/img/home/arrow_black.svg';
import what_we_img3 from "../assets/img/home/what_we_img3.svg";
import ux_path from "../assets/img/home/ux_path.svg";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class branding extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        <Helmet>
          <title>
            {" "}
            The Best SaaS Websites UI/UX DesignServices | trulydesignfirm.com{" "}
          </title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Affordable SaaS website UI/UX DesignServices focused on designing the right online experience for your Enterprises online leads and sales."
          />
          <meta
            name="keywords"
            content="saas website design, saas website design services, saas website uiux design, saas website uiux services, saas graphic design, saasuiux graphic design, saasuiux graphic design services, saas website design, saasux design, saas product design"
          />
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                {/* <img src={what_we_img3} alt="course"/> */}
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <h1>Branding and identity</h1>
                  <h3>Brand Identity Design Services</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>INTRODUCTION</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div class="mission_paras">
                  <p>
                    The one thing that sets you apart from your competitors is
                    Brand. Branding is an extremely important aspect of a
                    business; it’s as important as having a quality product or
                    service. Having a strong brand sells and helps customers
                    form a relationship. Branding is more than just a name, font
                    style, or color. It’s the face of your business. At Truly,
                    we aim to help businesses and start-ups get their branding
                    right.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* banner ends */}
        <section class="mission expertise-cards branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    branding <br></br> Services
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>
                    We Provide all Branding Related Services so that you don’t
                    have to Go Anywhere else
                  </h2>
                  {/* <p></p> */}
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Brand <br></br>Logo Design{" "}
                        </h5>
                        <p>
                          Your logo is what helps users to quickly associate
                          with your business. Creating logos that shape your
                          brand’s story, core values, and beliefs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Branding <br></br>Guide{" "}
                        </h5>
                        <p>
                          How you present your brand to the world is
                          quintessential. From the design system to defining
                          components and design elements, everything holds equal
                          importance. A Brand Guide helps throughout your
                          journey.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Brand <br></br> Strategy{" "}
                        </h5>
                        <p>
                          Brand Strategy is the key to bridging the gap between
                          how your brand is perceived and how you want your
                          brand to be perceived by the world.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Branding <br></br> Stationery Design{" "}
                        </h5>
                        <p>
                          Your brand’s stationary handouts play an important
                          role. It helps users remember you and also enhances
                          the message you want to convey.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Print <br></br> Media Design{" "}
                        </h5>
                        <p>
                          A physical entity, say, a brochure holds as much
                          importance as a digital entity holds. They communicate
                          your brand to customers even in your absence while
                          also gaining you important ones.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-empty"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="innerpagebranding">
          <section class="mission branding-sec pb-0">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                  <div class="section-header">
                    <p>
                      BRANDING<br></br>PROCESS{" "}
                    </p>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                  <figure class="d-block d-sm-block d-md-none">
                    <div class="section-header mt-3">
                      <h2>
                        A Brand is a Story that is Sold & We Make you a Brand{" "}
                      </h2>
                    </div>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <div class="section-header">
                      <h2>
                        A Brand is a Story that is Sold <br></br>& We Make you a
                        Brand{" "}
                      </h2>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section class="mission branding-sec pt-0">
            <div className="container">
              <div class="row branding-row">
                <div class="col-lg-6 col-md-12 col-xs-12">
                  <img
                    src={branding_process_img}
                    class="branding-process-img"
                    alt="branding-process-image"
                  />
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12">
                  <ul>
                    <li>
                      <h5>Perception</h5>
                      <p>
                        {" "}
                        Gathering insights on the consumers’ feelings - negative
                        and positive, and thoughts that follow when they hear
                        and see the brand.
                      </p>
                    </li>
                    <li>
                      <h5>Messaging</h5>
                      <p>
                        Putting together the right tone, context, and words in a
                        way that your customers most relate to and motivate them
                        to use your product.
                      </p>
                    </li>
                    <li>
                      <h5>Tone of Voice</h5>
                      <p>
                        Conveying your brand’s personality to consumers through
                        the right choice of words and an acceptable tone of
                        voice.
                      </p>
                    </li>
                    <li>
                      <h5>Positioning</h5>
                      <p>
                        Helping consumers register your brand as a certain
                        entity that they can most relate to
                      </p>
                    </li>
                    <li>
                      <h5>Story</h5>
                      <p>
                        Narrating the history of how, why and when your brand
                        came to be to convey your motto to your audience.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section class="learn_things " id="shop">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-3">
                <div class="section-header">
                  <p class="">Our Works</p>
                </div>
              </div>

              <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
                <div className="row work_row customer-row project_rows">
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Adtru} className="img-fluid" alt="adtru" />
                        </div>
                        <div>
                          <img src={Adtru1} className="img-fluid" alt="adtru" />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>adtru</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ADTRU advertising agency covers all forms of offline
                            promotion such as Hoardings, Car and Auto ads and
                            more. With our thoughtful design, we established
                            their visual identity and conveyed their intent
                            through posters for publicity and advertising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Df1} className="img-fluid" alt="df" />
                        </div>
                        <div>
                          <img src={Df2} className="img-fluid" alt="df" />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>deepforge</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Deepforge AI helps businesses to integrate one of
                            the most important and highly expected
                            functionality, conversational user experience in
                            their websites or mobile apps. We provided them the
                            critical Branding & identity services and designed
                            posters for publicity and advertising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* hiring */}

        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Make your Brand. Get in Touch with us.</h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Make your Brand.
                      <br></br>Get in Touch with us.{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default branding;
