import React, { Component } from "react";
import footer_logo from "../assets/img/footer_logo.svg";
import truly_logo from "../assets/img/home/truly_logo.svg";
import footer_pencil from "../assets/img/footer_pencil.png";
import sree_name from "../assets/img/sree_name.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import * as $ from "jquery";
// import * as jquery from 'jquery';

class Footer extends Component {
  componentDidMount() {
    // var activeNavItem = $('.links li');
    //    activeNavItem.click(function(){
    //      activeNavItem.removeClass('active');
    //      $(this).addClass('active');
    //    });
    //   $(document).ready(function(){
    //     $('.links li').click(function(event){
    //         //remove all pre-existing active classes
    //         $('.active-tab').removeClass('active-tab');
    //         //add the active class to the link we clicked
    //         $(this).addClass('active-tab');
    //         //Load the content
    //         //e.g.
    //         //load the page that the link was pointing to
    //         //$('#content').load($(this).find(a).attr('href'));
    //         // event.preventDefault();
    //     });
    // });
  }
  render() {
    return (
      <div>
        {/* followw */}
        <section class="learn_things follow_us">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    FOR<br></br> MORE<br></br> UPDATES
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div class="section-header learn_head">
                  <div>
                    <h2>Follow us on Social Media</h2>
                  </div>
                </div>
                <div class="mission_paras community_para">
                  <p>
                    For the latest updates in the field of{" "}
                    <span className="text-uppercase">UX/UI</span> design, ideas,
                    and more, find us on
                  </p>
                </div>
                <div class="social_icons">
                  {/* <div>
                    <a
                      href="https://www.behance.net/trulydesingfirm"
                      target="_blank"
                    >
                      <img
                        src={behance_logo}
                        class="facebook_icon"
                        alt="social_icons"
                      />
                      <p>Behance</p>
                    </a>
                  </div> */}
                  <div>
                    <a
                      href="https://www.facebook.com/Truly-Design-Firm-100296892233901/"
                      target="_blank"
                    >
                      <img
                        src={facebook}
                        class="facebook_icon"
                        alt="social_icons"
                      />
                      <p>Facebook</p>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/trulydesignfirm/"
                      target="_blank"
                    >
                      <img
                        src={instagram}
                        class="facebook_icon"
                        alt="social_icons"
                      />
                      <p>Instagram</p>
                    </a>
                  </div>
                  {/* <div>
           <a href="">
           <img src={twitter} alt="social_icons"/>
            <p>Twitter</p>
           </a>
          </div> */}
                  <div>
                    <a
                      href="https://www.youtube.com/channel/UC4GvxB8rrfws9jY1At6gVPg"
                      target="_blank"
                    >
                      <img src={youtube} alt="social_icons" />
                      <p>Youtube</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="footer">
          <div class="container">
            <div>
              <img src={footer_pencil} class="footer_pencil" alt="pencil" />
            </div>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0 left">
                <div class="section-header hiring_header">{/* <p></p> */}</div>
                <figure class="d-none d-sm-none d-md-block">
                  <img src={sree_name} className="sree_name" alt="img" />
                </figure>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-xs-12">
                    <ul className="links">
                      <li class="course">Pages</li>
                      <Link to="/">
                        <li className="">Home</li>
                      </Link>
                      <Link to="/about">
                        <li>About Us</li>
                      </Link>
                      <Link to="/what-we-do">
                        <li>What we do</li>
                      </Link>
                      <Link to="/works">
                        <li>Our Works</li>
                      </Link>
                      <Link to="/process">
                        <li>The Process</li>
                      </Link>
                      <Link to="/manifesto">
                        <li>Manifesto</li>
                      </Link>
                      <Link to="/testimonials">
                        <li>Testimonials</li>
                      </Link>
                      <Link to="/clients">
                        <li>clients</li>
                      </Link>
                      <Link to="/resources">
                        <li>Resources</li>
                      </Link>
                      {/* <Link to="/contact"><li>Contact Us</li></Link> */}
                      {/* <Link to="/contact"> */}
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                        target="_blank"
                      >
                        <li>contact us</li>
                      </a>
                      {/* </Link> */}
                    </ul>
                  </div>
                  <div class="col-lg-4 col-md-4 col-xs-12">
                    <ul>
                      <li class="course">DESIGN SERVICES</li>
                      <Link to="/branding">
                        <li>Branding & Identity</li>
                      </Link>
                      <li>Naming & Story Telling</li>
                      <Link to="/ux-design">
                        <li>
                          Product <span className="text-uppercase">UX </span>
                          Design
                        </li>
                      </Link>
                      <li>
                        Product <span className="text-uppercase">UI </span>
                        Design
                      </li>
                      <li>Creative Marketing</li>

                      <li>Website Design</li>
                      <li>Mobile app design</li>

                      {/* <li>Packaging Design</li> */}

                      <Link to="/custom-design">
                        <li>Custom design </li>
                      </Link>
                    </ul>
                  </div>
                  <div class="col-lg-4 col-md-4 col-xs-12">
                    <ul>
                      <li class="course">CONTACT</li>
                      <a href="mailto:hello@trulydesignfirm.com">
                        <li>hello@trulydesignfirm.com</li>
                      </a>
                      <a href="tel:+91 9059028599">
                        <li>+91 9059028599</li>
                      </a>
                    </ul>
                    <ul className="mt-5">
                      <li class="course">ADDRESS</li>
                      <li className="mb-0">
                        <img src={truly_logo} class="footer_logo" alt="logo" />{" "}
                      </li>
                      <li>
                        Yasaswini Residency N.Qube Homes, 4th Floor, Khanamet,
                        Madhapur, Hyderabad, Telangana 500081.
                      </li>
                    </ul>
                    <figure class="d-block d-sm-block d-md-none">
                      <img
                        src={sree_name}
                        className="sree_name mt-3"
                        alt="img"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="copy_rights">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12"></div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div className="d-flex align-items-center">
                  <p>
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>{" "}
                    | Privacy Policy
                  </p>
                  <p>
                    Copyright © 2020{" "}
                    <a href="https://trulydesignfirm.com/" target="_blanck">
                      Truly tech solutions Pvt. Ltd
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Footer;
