import React, { Component } from "react";
import what_we_img1 from "../assets/img/home/what_we_img1.svg";
import what_we_img2 from "../assets/img/home/what_we_img2.svg";
import what_we_img3 from "../assets/img/home/what_we_img3.svg";
import ono from "../assets/img/home/ono.png";
import client1 from "../assets/img/home/client1.svg";
import client2 from "../assets/img/home/client2.png";
import client3 from "../assets/img/home/client3.svg";
import client4 from "../assets/img/home/client4.svg";
import client5 from "../assets/img/home/client5.png";
import client6 from "../assets/img/home/client6.svg";
import client7 from "../assets/img/home/client7.svg";
import client8 from "../assets/img/home/client8.svg";
import client9 from "../assets/img/home/client9.svg";
import client10 from "../assets/img/home/client10.png";
import bnr_img from "../assets/img/home/bnr_img.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import wash_small from "../assets/img/home/wash_small.svg";
import work_img1 from "../assets/img/home/work_img1.png";
import work_img2 from "../assets/img/home/work_img2.png";
import Ono1 from "../assets/img/works/Ono1.png";
import Ono from "../assets/img/works/Ono.png";
import Nextergo from "../assets/img/works/Nextergo.png";
import Nextergo1 from "../assets/img/works/Nextergo1.png";
import Adtru from "../assets/img/works/Adtru.png";
import Adtru1 from "../assets/img/works/Adtru1.png";
import Df1 from "../assets/img/works/Df1.png";
import Df2 from "../assets/img/works/Df2.png";
import Ditebowl1 from "../assets/img/works/Ditebowl1.png";
import Ditebowl from "../assets/img/works/Ditebowl.png";
import Washhit1 from "../assets/img/works/Washhit1.png";
import Washhit2 from "../assets/img/works/Washhit2.png";
import starfire from "../assets/img/works/starfire.png";

import what_we_img4 from "../assets/img/home/what_we_img4.svg";
import cust1 from "../assets/img/home/cust1.png";
import cust2 from "../assets/img/home/cust2.png";
import cust3 from "../assets/img/home/cust3.png";
import cust4 from "../assets/img/home/cust4.png";
import cust5 from "../assets/img/home/cust5.png";
import cust6 from "../assets/img/home/cust6.png";
import quote from "../assets/img/home/quote.svg";
import adobe from "../assets/img/home/adobe.svg";
import illustrator from "../assets/img/home/illustrator.svg";
import photoshop from "../assets/img/home/photoshop.svg";
import symbol from "../assets/img/home/symbol.svg";
import figma from "../assets/img/home/figma.svg";
import zeplin from "../assets/img/home/zeplin.svg";
import invision_s from "../assets/img/home/invision_s.svg";
import invision from "../assets/img/home/invision.svg";
import group from "../assets/img/home/group.svg";
import sketch from "../assets/img/home/sketch.svg";

import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";

import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Home extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
        navText: [
          '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          '<i class="fa fa-angle-right" aria-hidden="true"></i>',
        ],
      },
      450: {
        items: 1,
      },
      // 600: {
      //     items: 1,
      // },
      1000: {
        items: 1.8,
        //             autoplay: true,
        //             slideTransition: 'linear',
        // autoplaySpeed: 3000,
        // autoplayTimeout: 3000,
        // autoplayHoverPause: true,
      },
    },
    // autoHeight : true,
    // transitionStyle:"fade"
  };
  state1 = {
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
        navText: [
          '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          '<i class="fa fa-angle-right" aria-hidden="true"></i>',
        ],
      },
      450: {
        items: 1,
      },
      // 600: {
      //     items: 1,
      // },
      1000: {
        items: 3,
      },
    },
    dots: true,
  };

  componentDidMount() {
    $(".shop-tab").click(function () {
      $(".shop_tab").css("display", "block");
      $(".access_tab").css("display", "none");
      $(".resourse_tab").css("display", "none");
    });
    $(".res-tab").click(function () {
      $(".resourse_tab").css("display", "block");
      $(".shop_tab").css("display", "none");
      $(".access_tab").css("display", "none");
    });
    $(".acc-tab").click(function () {
      $(".access_tab").css("display", "block");
      $(".shop_tab").css("display", "none");
      $(".resourse_tab").css("display", "none");
    });
    $(document).ready(function () {
      $(".tabs-left ul li a").click(function () {
        $(".tabs-left li a p").removeClass("active");
        $(".tabs-left li a p").addClass("active");
      });
    });
  }

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            {/* <div class="row">
      <div class="col-lg-7 col-md-6 col-xs-12">
      <figure class="d-block d-sm-block d-md-none"><img src={bnr_img} class="banner_img" alt="banner"/></figure>
       <div class="banner_text home_bnr_text">
       <h1>full-service UX/UI design firm
</h1>
       <figure class="d-block d-sm-block d-md-none">
       <h3>We help entrepreneurs achieve business success
</h3>
  
       </figure>
       <figure class="d-none d-sm-none d-md-block">
       <h3>We help entrepreneurs


</h3>
      <h3>achieve business success</h3>
                    </figure>
   
                    <Link to="/contact"><button class="primary_btn bnr_btn">contact us<img src={arrow_black} className=
      "arrow" alt="arrow"/></button></Link>
       </div>
      </div>
      <div class="col-lg-5 col-md-6 col-xs-12">
      <figure class="d-none d-sm-none d-md-block">
                    <img src={bnr_img} class="banner_img" alt="about-banner-image"/>
                    </figure>
         
      </div>
  </div> */}
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header"></div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-flex">
                  <div class="banner_text home_bnr_text">
                    <h1>full-service UX/UI design firm</h1>
                    <figure class="d-block d-sm-block d-md-none">
                      <h3>We help entrepreneurs achieve business success</h3>
                    </figure>
                    <figure class="d-none d-sm-none d-md-block">
                      <h3>We help entrepreneurs</h3>
                      <h3>achieve business success</h3>
                    </figure>

                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn bnr_btn">
                        contact us
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                  {/* <img src={bnr_img} class="banner_img" alt="about-banner-image"/> */}
                </figure>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        {/* mission */}
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>INTRODUCTION</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header mt-3">
                    <h2>
                      We create seamless, unforgettable, and unique User
                      Experiences{" "}
                    </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header">
                    <h2>We create seamless, unforgettable, </h2>
                    <h2>and unique User Experiences</h2>
                  </div>
                </figure>

                <div class="mission_paras">
                  <p>
                    Our prime focus is through simple, intelligent design. From
                    building your brand from the basics to ensuring the best{" "}
                    <span className="text-uppercase">UX</span>, Truly does 360°
                    design solutions for your business. Focus on your business
                    and let us do what we do best. At Truly, we believe in
                    helping businesses accomplish their objectives in the best
                    and most effective possible ways. We strive for long-lasting
                    relationships with our clients that stand on the pillars of
                    trust, understanding, and work that they truly love.{" "}
                  </p>

                  <h5>Welcome to the Truly Design Firm</h5>
                  <div className="d-flex all_btns">
                    <Link to="/about">
                      <button class="primary_btn">
                        Know More
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                    </Link>
                    {/* <a><h6>see all works</h6></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* courses */}
        <section class="courses what-we" id="courses">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header">
                  <p>WHAT WE DO </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header learn_head hiring_header mt-3">
                    <div>
                      <h2>We help businesses get their designs right</h2>
                    </div>
                    {/* <a href="" className="learn_linkweb"> see all services</a> */}
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header learn_head hiring_header ">
                    <div>
                      <h2>We help businesses </h2>
                      <h2>get their designs right</h2>
                    </div>
                    {/* <a href="" className="learn_linkweb"> see all services</a> */}
                  </div>
                </figure>

                <figure class="d-none d-sm-none d-md-block">
                  <div class="row courses_row ">
                    <OwlCarousel
                      items={3}
                      className="owl-theme product_images what_we_do_c what_we_do_cs"
                      loop
                      margin={40}
                      responsive={this.state1.responsive}
                    >
                      <div className="item">
                        <Link to="/branding">
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img3} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>brand identity </h5>
                                <h5>design services</h5>
                              </div>
                              <p className="mb-0">
                                Our Branding Solutions ensure that your brand
                                stands unique, from identity to packaging.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="item">
                        {" "}
                        <Link to="/ux-design">
                          {" "}
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img2} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>Enterprise Product </h5>
                                <h5>
                                  {" "}
                                  <span className="text-uppercase">
                                    UX
                                  </span>{" "}
                                  Design Services
                                </h5>
                              </div>
                              <p className="mb-0">
                                Crafting{" "}
                                <span className="text-uppercase">UX</span>{" "}
                                Designs that are enjoyable, solve real problems
                                and fit your business goals.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="item">
                        <Link>
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img4} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>CREATIVE</h5>
                                <h5> MARKETING</h5>
                              </div>
                              <p className="mb-0">
                                Marketing strategy, campaign activation, events,
                                and creative support for internal and external
                                communications.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="item">
                        <Link to="/custom-design">
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img1} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>Custom Design </h5>
                                <h5> Services</h5>
                              </div>
                              <p className="mb-0">
                                Delivering web designs that work seamlessly,
                                engage, and enable interaction for your specific
                                business.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </OwlCarousel>
                  </div>
                </figure>
                <figure class="d-block d-sm-block d-md-none">
                  <div class="courses_row ">
                    <OwlCarousel
                      items={1}
                      className="owl-theme what_we_do_c course_mob_dots"
                      loop
                      margin={20}
                    >
                      <div>
                        <Link to="/branding">
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img3} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>brand identity </h5>
                                <h5>design services</h5>
                              </div>
                              <p className="mb-0">
                                Our Branding Solutions ensure that your brand
                                stands unique, from identity to packaging.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link to="/ux-design">
                          {" "}
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img2} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>Enterprise Product </h5>
                                <h5>
                                  {" "}
                                  <span className="text-uppercase">
                                    UX
                                  </span>{" "}
                                  Design Services
                                </h5>
                              </div>
                              <p className="mb-0">
                                Crafting{" "}
                                <span className="text-uppercase">UX</span>{" "}
                                Designs that are enjoyable, solve real problems
                                and fit your business goals.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link to="/custom-design">
                          <div class="card">
                            <div class="courses_images">
                              <img src={what_we_img1} alt="course" />
                            </div>
                            <div class="card-body">
                              <div class="course-head">
                                <h5>Custom Design </h5>
                                <h5> Services</h5>
                              </div>
                              <p className="mb-0">
                                Delivering web designs that work seamlessly,
                                engage, and enable interaction for your specific
                                business.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </OwlCarousel>
                  </div>
                </figure>
                <div className="d-flex all_btns ser_btns">
                  {/* <a href="https://wa.me/919059028599" target="_blank"><button class="primary_btn">contact us<img src={arrow_black} className=
      "arrow" alt="arrow"/></button></a> */}
                  {/* <Link to="/contact"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                    <button class="primary_btn">
                      contact us
                      <img src={arrow_black} className="arrow" />
                    </button>
                    </a>
                  {/* </Link> */}
                  <Link to="/what-we-do">
                    <h6>see all services</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="learn_things " id="shop">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-3">
                <div class="section-header">
                  <p class="">Our Works</p>
                </div>
              </div>

              <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
                {/* <!-- Tab panes --> */}
                <div class="tab-content">
                  <div class="tab-pane active" id="home-v">
                    <figure class="d-block d-sm-block d-md-none">
                      <div class="section-header learn_head mt-3">
                        <h2>
                          Let our designs speak for us. Check out our case
                          studies.
                        </h2>
                      </div>
                    </figure>
                    <figure class="d-none d-sm-none d-md-block">
                      <div class="section-header">
                        <h2>Let our designs speak for us.</h2>
                        <h2> Check out our case studies.</h2>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows section_row">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Washhit1}
                            className="img-fluid"
                            alt="washhit"
                          />
                        </div>
                        <div>
                          <img
                            src={Washhit2}
                            className="img-fluid"
                            alt="washhit"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>washhit</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">Ui</span>,{" "}
                            <span className="text-uppercase">UX</span> | Website
                            Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Washh it provides comprehensive dry cleaning &
                            laundry services including pickup and delivery times
                            that suit their customers. We provided complete
                            design services for them.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-8 col-md-8 col-xs-12">
        <div className="card">
        <OwlCarousel items={1} dots={true} className="owl-theme product_images project_images" loop margin={40} >
       <div>
       <img src={Ono} className="img-fluid" alt="ono"/>
       </div>
       <div>
       <img src={Ono1} className="img-fluid" alt="ono"/>
       </div>
        </OwlCarousel>
        <div className="card-body px-0 pb-0">
          <h4>ono</h4>
            <div className="card-head">
           <h6 className="work_head">Branding & identity | website design product UI UX design | Graphic design services 
</h6>
            </div>
            <div className="card-text mt-0 mb-0">
              <p className="mb-0">ONO provides real-time information of mandi prices of many crops so that farmers are better informed and ultimately gain good profits or mitigate huge losses. Our end to end UI UX design took care of all the digital presence of the service.</p>
            </div>
          </div>
         </div>
          </div> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Adtru} className="img-fluid" alt="adtru" />
                        </div>
                        <div>
                          <img src={Adtru1} className="img-fluid" alt="adtru" />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>adtru</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ADTRU advertising agency covers all forms of offline
                            promotion such as Hoardings, Car and Auto ads and
                            more. With our thoughtful design, we established
                            their visual identity and conveyed their intent
                            through posters for publicity and advertising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Ditebowl}
                            className="img-fluid"
                            alt="dietbowl"
                          />
                        </div>
                        <div>
                          <img
                            src={Ditebowl1}
                            className="img-fluid"
                            alt="dietbowl"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>diet bowl</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Diet bowl provides pure organic Cow Ghee that are
                            useful for various purposes. Our ,
                            <span className="text-uppercase">UI UX</span> Design
                            agency covered all the aspects of Branding &
                            Identity and designing posters.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Df1} className="img-fluid" alt="df" />
                        </div>
                        <div>
                          <img src={Df2} className="img-fluid" alt="df" />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>deepforge</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Deepforge AI helps businesses to integrate one of
                            the most important and highly expected
                            functionality, conversational user experience in
                            their websites or mobile apps. We provided them the
                            critical Branding & identity services and designed
                            posters for publicity and advertising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row work_row customer-row project_rows section_row">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Nextergo}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div>
                          <img
                            src={Nextergo1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>nextergo</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design{" "}
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Nextergo provides standing desks that are truly
                            smart and have a lot of features. Our{" "}
                            <span className="text-uppercase">UI UX</span> design
                            firm designed all of the branding & identity and
                            other aspects of digital presence.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card mb-0">
                      <div class="small_img">
                        <img
                          src={starfire}
                          className="img-fluid"
                          alt="starfire"
                        />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>Starfire </h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Publicity and Advertising
                            Poster Designing | jewellery thoughts | printed
                            collateral
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Starfire is an e-commerce website that sells various
                            jewellery. In Addition to the Branding & Poster
                            designing services, we also provided new jewellery
                            thoughts and printed collateral.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex all_btns">
                  <Link to="/works">
                    <button class="primary_btn">
                      see all works
                      <img src={arrow_black} className="arrow" alt="image" />
                    </button>
                  </Link>
                  {/* <a><h6>see all </h6></a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="courses what-we" id="courses">
          <div class="container ">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header">
                  <p>
                    TECHNOLOGIES<br></br> WE USE{" "}
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header learn_head hiring_header mt-3">
                    <div>
                      <h2>
                        Our productivity is a measure of value that is able to
                        impart with its every bit.
                      </h2>
                    </div>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header learn_head hiring_header ">
                    <div>
                      <h2 className="mt-0">
                        Our productivity is a measure of value{" "}
                      </h2>
                      <h2>that is able to impart with its every bit.</h2>
                    </div>
                  </div>
                </figure>
                <div className="technologies1">
                  <div class="technologies" id="main">
                    <img src={adobe} alt="photoshop-cc" class="icon" />
                    <img src={illustrator} alt="illusrator" class="icon" />
                    <img src={photoshop} alt="adobeXd" class="icon" />
                    <img src={invision} alt="invision" class="icon" />
                    <img src={invision_s} alt="invision-studio" class="icon" />
                  </div>
                  <div class="technologies" id="main">
                    <img src={sketch} alt="sketch-2" class="icon" />
                    <img src={zeplin} alt="zeplin" class="icon" />
                    <img src={group} class="group-icon icon" />
                    <img src={symbol} alt="symbol" class="icon" />
                    <img src={figma} alt="plus" class="icon" />
                  </div>
                </div>

                <div class="technologies2">
                  <div class="row section_row section_row2" id="main">
                    <div class="col-4">
                      <img src={photoshop} alt="photoshop-cc" class="icon" />
                    </div>
                    <div class="col-4">
                      <img src={illustrator} alt="illusrator" class="icon" />
                    </div>
                    <div class="col-4">
                      <img src={adobe} alt="adobe-xd-1" class="icon" />
                    </div>
                  </div>
                  <div class="row section_row2" id="main">
                    <div class="col-4">
                      <img src={invision} alt="invision" class="icon" />
                    </div>
                    <div class="col-4">
                      <img
                        src={invision_s}
                        alt="invision-studio"
                        class="icon"
                      />
                    </div>
                    <div class="col-4">
                      <img src={sketch} alt="sketch-2" class="icon" />
                    </div>
                  </div>
                  <div class="row section_row2" id="main">
                    <div class="col-4">
                      <img src={zeplin} alt="zeplin" class="icon" />
                    </div>
                    <div class="col-4">
                      <img src={group} alt="group-icon" class="icon" />
                    </div>
                    <div class="col-4">
                      <img src={symbol} alt="symbol" class="icon" />
                    </div>
                  </div>
                  <div class="row section_row2" id="main">
                    <div class="col-4">
                      <img src={figma} alt="plus" class="icon tech-last-icon" />
                    </div>
                  </div>
                </div>
                <div className="d-flex all_btns">
                  {/* <a href="" target="_blank"><button class="primary_btn">see all services<img src={arrow_black} className=
      "arrow"/></button></a> */}
                  {/* <a><h6>see all works</h6></a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!---------------------technologies start----------------------> */}
        {/* <section class="learn_things" >
<div class="container">
  
  
</div>
</section> */}
        {/* <!---------------------technologies end----------------------> */}
        {/* community */}
        <section class="mission community" id="community">
          <div class="container pr-0">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Our customers</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0">
                <div class="section-header join_header learn_head">
                  <div>
                    <h2>Our customers say we are reliable </h2>
                  </div>
                </div>
                <div class="mission_paras community_para">
                  <p className="mb-0">
                    Reviews from our happy clients are what drives us to give
                    our best
                  </p>
                </div>
                <div class="customer-row section_row" id="myDIV">
                  <OwlCarousel
                    items={3}
                    className="owl-theme product_images what_we_do_c cust_car"
                    loop
                    margin={40}
                    dots={true}
                    responsive={this.state.responsive}
                  >
                    <div>
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>Nuacem</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                I approached Truly with a basic "feel" I wanted
                                in a website. They helped our new site here at
                                Nuacem. Truly services are bargain when you
                                weigh the cost versus the product...
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust1}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Vamsi Krishna</h5>
                                <p>Founder & CEO</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      {" "}
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>Starfire</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                My new site created by Truly just launched.
                                They've done a great job of creating exactly
                                what I wanted. In addition, they add a lot of
                                effects to a design which makes...
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust2}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Shweta</h5>
                                <p>Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>Body workout</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                I knew I was going to enjoy working with
                                Sreedhar and the team of energetic and creative
                                professionals.I am really happy with what they
                                have delivered...
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust3}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Akheel</h5>
                                <p>Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div>
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>IDEEO</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                Truly is definitely a great designer partner.
                                They set us up for success. The design team far
                                exceeded my expectations, Scope was structured
                                and clear on every front...
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust4}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Sridhar</h5>
                                <p>Foundr & Design head</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>HEPC</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                "The logo is a head-turner and has regenerated
                                our company’s image". Truly delivered our logo
                                design promptly, including the versions we
                                requested. Highly recommended!
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust5}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Chandra Sekhar</h5>
                                <p>Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div>
                      <Link to="/testimonials">
                        <div class="card cust-card active">
                          <div class="card-body cust-card">
                            <div class="card-head">
                              {/* <!-- <i class="fa fa-quote-left" aria-hidden="true"></i> --> */}
                              <img src={quote} class="fa" alt="quote" />
                              <h6>SSM Institute</h6>
                            </div>
                            <div class="card-text">
                              <p class="testmonial-txt">
                                Truly constructed a new website for us that is
                                perfect and they did it quickly and efficiently
                                . They also created a mobile friendly format for
                                cell phones...
                              </p>
                              {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                              <p>
                                <a href="https://trulydesignfirm.com/testimonials.html#testimonial-sec">
                                  Read more
                                </a>
                              </p>
                            </div>
                            <div class="card-footer1">
                              <img
                                src={cust6}
                                class="cust-img"
                                alt="truly-client-vamsi"
                              />
                              <div class="">
                                <h5>Indarjith Mathivanan</h5>
                                <p>Founder & CEO</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </OwlCarousel>
                </div>
                <div className="d-flex all_btns cust_btns">
                  <a
                    href="https://www.google.com/search?q=truly+design+firm&oq=tr&aqs=chrome.2.69i60j69i57j69i59j69i60l2j69i61l3.3115j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3bcb93d96b4b9343:0xc993ebee531522fb,3,,,"
                    target="_blank"
                  >
                    <button class="primary_btn">
                      give a review <img src={arrow_black} className="arrow" />
                    </button>
                  </a>
                  <Link to="/testimonials">
                    <h6>see all reviews</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="courses what-we" id="courses">
          <div class="container pr-0">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 p-0">
                <div class="section-header hiring_header">
                  <p>OUR CLIENTS </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header learn_head hiring_header mt-3">
                    <div>
                      <h2>
                        Behind each creative design, is our ethic that adds
                        value{" "}
                      </h2>
                    </div>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header learn_head hiring_header ">
                    <div>
                      <h2>Behind each creative design, </h2>
                      <h2>is our ethic that adds value</h2>
                    </div>
                  </div>
                </figure>

                <div class="client-sec">
                  <div class="client-row slider">
                    <div class="slide-track">
                      <div class="slide">
                        <img src={ono} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client1} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client2} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client3} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client4} alt="image" />
                      </div>

                      <div class="slide">
                        <img src={client6} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client7} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client8} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client5} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client9} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client10} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={ono} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client1} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client2} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client3} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client4} alt="image" />
                      </div>

                      <div class="slide">
                        <img src={client6} alt="imageimage" />
                      </div>
                      <div class="slide">
                        <img src={client7} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client8} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client5} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client9} alt="image" />
                      </div>
                      <div class="slide">
                        <img src={client10} alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex all_btns">
                  <Link to="/clients">
                    <button class="primary_btn">
                      see all clients{" "}
                      <img src={arrow_black} className="arrow" alt="image" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>contact us</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Want to discuss your Next Project? </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>Want to discuss your </h2>
                    <h2>Next Project?</h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
