import React, { Component } from "react";

import contact_bnr from "../assets/img/home/contact_bnr.svg";

import arrow_black from "../assets/img/home/arrow_black.svg";

import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "jquery-validation/dist/jquery.validate.js";
import "jquery-validation/dist/additional-methods.min.js";

class contact extends Component {
  componentDidMount() {
    $("input").on("focusin", function () {
      $(this).parent().find("label").addClass("formLabelActive");
    });
    $("input").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("formLabelActive");
      }
    });
    $("textarea").on("focusin", function () {
      $(this).parent().find("label").addClass("formLabelActive");
    });
    $("textarea").on("focusout", function () {
      if (!this.value) {
        $(this).parent().find("label").removeClass("formLabelActive");
      }
    });

    // $('select').on('focusin', function() {
    //     $(this).parent().find('label').addClass('formLabelActive');
    // });

    // $('select').on('focusout', function() {
    //     if (!this.value) {
    //         $(this).parent().find('label').removeClass('formLabelActive');
    //     }
    // });
    $(document).ready(function () {
      $(".areatextfield").attr("hidden", true);
      $("select.areaDropdown").change(function () {
        var selectedArea = $(this).children("option:selected").val();
        if (selectedArea == "Other") {
          $(".areaDropdown").attr("hidden", true);
          $(".areatextfield").removeAttr("hidden");
          $(".areatextfield").attr("hidden", false);
          $(".areatextfield").css("background-image", "none");
        } else {
          $(".areatextfield").attr("hidden", true);
          $(".areaDropdown").removeAttr("hidden");
          $(".areaDropdown").attr("hidden", false);
        }
      });
      $("#phonenumber,#pincode").on("keypress keyup blur", function (event) {
        $(this).val(
          $(this)
            .val()
            .replace(/[^\d].+/, "")
        );
        if (event.which < 48 || event.which > 57) {
          event.preventDefault();
        }
      });
      $("#basic-form").validate({
        rules: {
          name: {
            required: true,
            minlength: 3,
            pattern: /^[a-zA-Z," "]+$/,
          },
          email: {
            required: true,
            email: true,
            pattern: /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/,
          },

          phonenumber: {
            required: true,
            number: true,
            phoneUS: false,
            minlength: 10,
            maxlength: 12,
          },

          message: {
            required: true,
            maxlength: 200,
          },
        },
        messages: {
          name: {
            required: "Enter Name",
            minlength: "Name should contain minimum 3 letters",
            pattern: "Only alphabets and spaces are allowed",
          },
          email: {
            required: "Enter your e-mail id",
            pattern: "Enter valid Email Id",
          },

          phonenumber: {
            required: "Enter your mobile number",
            minlength: "Mobile number cannot be less than 10 digits",
            maxlength: "Mobile number cannot be greater than 10 digits",
            number: "Only digits are allowed",
          },
          message: {
            required: "Enter your message",
            maxlength: "Text more than 200 words is not allowed",
            pattern: "Only alphabets and spaces are allowed",
          },
        },
        submitHandler: function (form) {
          console.log("formmmmm");
          // var name = $("#fname").val();
          var name = $("#name").val();
          var email = $("#email").val();
          var phonenumber = $("#phonenumber").val();
          var select_area = $("#select_area").val();
          var message = $("#message").val();
          var country = $("#country").val();

          var arr = {
            Name: name,
            Email: email,
            Phonenumber: phonenumber,
            "Looking for?": select_area,
            Message: message,
            Country: country,
          };

          var FullData = JSON.stringify(arr);
          const formData = new FormData();
          formData.append("subject", "New Request for design");
          formData.append("title", "Truly Design Firm");
          formData.append(
            "description",
            "You have got a new design request. Please go through below information provided by client"
          );
          formData.append("data", FullData);

          $.ajax({
            // dataType : 'json',
            url: "https://api.trulydesignfirm.com/api/public/_/mail/",
            data: formData,
            cache: false,
            // headers: {'Access-Control-Allow-Origin': true },
            contentType: false,
            processData: false,
            type: "POST",
            success: function (response) {
              // $("#basic-form")[0].reset();
              alert("Successfully uploaded your details!");
              document.getElementById("basic-form").reset();
            },
            error: function (errors) {
              // console.log(data);
              alert("Failed to upload details!");
            },
          });

          // $.ajax({

          //     url: "mail.php",
          //     type: "post",
          //     data: form_data,
          //     success: function(data, status) {
          //         if (status == "success") {
          //           $('.form-submit').css( "display","none");
          //           $('#basic-form').removeClass('form-submit');
          //           $('#submitText').html("<h3>Thanks for being awesome!</h3> <br> We have received your message and would like to thank you for writing to us. If your inquiry is urgent, please use the telephone number listed below to talk to one of our staff members. Otherwise, we will reply by email as soon as possible. <br><br> We’ll get back to you very soon. <br><br><br> Truly tech solutions");
          //             $('#submitText').addClass('successActive');

          //             $('.form-control').removeClass('valid');

          //             $('.areatextfield').attr('hidden', true);
          //             $('.areaDropdown').removeAttr('hidden');
          //             $('.areaDropdown').attr('hidden', false);

          //         } else {
          //             $('#submitText').html("Your details are not saved succesfully");
          //             $('#submitText').addClass('errorActive');
          //             $('#submitText').fadeOut(2000);
          //         }

          //     }
          // });
          //  e.preventDefault();
        },
      });
    });

    // $(document).ready(function(){
    //     if (status == "success") {
    //         $('.submit').click(function(){
    //           $('.form-submit').css( "display","none");
    //           $('#submitText').html("Thanks! <br> We appreciate that you’ve taken the time to write us. We’ll get back to you very soon.");
    //          $('#submitText').addClass('successActive');
    //         });
    //       }
    //    });
  }

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                <figure class="d-none d-sm-none d-md-block">
                  <img src={contact_bnr} class="expert-bnr all_bnr_img" />
                </figure>
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img src={contact_bnr} class="expert-bnr all_bnr_img" />
                </figure>
                <div class="banner_text banner_mob_text">
                  <h1>contact us</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Got Questions? We have Answers!</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Got Questions? <br></br> We have Answers!
                    </h3>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission " id="contactus">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Let’s Talk</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  <h2>Contact Form</h2>
                </div>
                <section id="contact_form">
                  <div class="row contact-row">
                    <div class="col-lg-8 col-xs-12 form-mob p-0">
                      <div class="form-submit">
                        <form id="basic-form" method="post" class="">
                          <div class="row marginForm">
                            <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                              <div class="input-group ">
                               
                                <input
                                  class="form-control mb-0"
                                  type="text"
                                  name="name"
                                  autoComplete="off"
                                  id="name"
                                  placeholder="Name*"
                                  required
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-mob">
                              <div class="input-group ">
                               
                                <input
                                  class="form-control mb-0"
                                  type="tel"
                                  name="phonenumber"
                                  maxlength="12"
                                  placeholder="Phone Number*"
                                  autoComplete="off"
                                  id="phonenumber"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row marginForm">
                            <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                              <div class="input-group ">
                              
                                <input
                                  class="form-control mb-0"
                                  type="email"
                                  name="email"
                                  autoComplete="off"
                                  id="email"
                                  placeholder="Email*"
                                  required
                                />
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12 form-mob">
                              <div class="input-group select-group">
                              
                                <select
                                  id="select_area"
                                  name="select_area"
                                  class="form-control areaDropdown select-option my_class"
                                  required
                                >
                                  <option value="">Looking for ?*</option>
                                  <option value="Branding & Identity">
                                    Branding & Identity
                                  </option>
                                  <option value="UX Design Services">
                                    UX Design Services
                                  </option>
                                  <option value="Custom Design Services">
                                    Custom Design Services
                                  </option>
                                </select>
                                
                              </div>
                            </div>
                          </div>
                          <div class="row marginForm2">
                            <div class="col-lg-12 col-md-12 col-sm-12 pl-0">
                              <div class="input-group ">
                                
                                <textarea
                                  class="form-control mb-0"
                                  id="message"
                                  type="message"
                                  name="message"
                                  autoComplete="off"
                                  placeholder="Message"
                                  rows="3"
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row marginForm">
                            <div class="col-lg-6 col-md-6 col-sm-12 pl-0">
                              <div class="input-group select-group">
                              
                                <select
                                  id="country"
                                  name="country"
                                  class="form-control areaDropdown select-option my_class"
                                  required
                                >
                                  <option value="">Location*</option>
                                  <option value="Afganistan">
                                    Afghanistan
                                  </option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">
                                    American Samoa
                                  </option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">
                                    Antigua & Barbuda
                                  </option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">
                                    Bosnia & Herzegovina
                                  </option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">
                                    British Indian Ocean Ter
                                  </option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">
                                    Burkina Faso
                                  </option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Canary Islands">
                                    Canary Islands
                                  </option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">
                                    Cayman Islands
                                  </option>
                                  <option value="Central African Republic">
                                    Central African Republic
                                  </option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">
                                    Channel Islands
                                  </option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">
                                    Christmas Island
                                  </option>
                                  <option value="Cocos Island">
                                    Cocos Island
                                  </option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">
                                    Cook Islands
                                  </option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">
                                    Cote DIvoire
                                  </option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">
                                    Czech Republic
                                  </option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">
                                    Dominican Republic
                                  </option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">
                                    El Salvador
                                  </option>
                                  <option value="Equatorial Guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">
                                    Falkland Islands
                                  </option>
                                  <option value="Faroe Islands">
                                    Faroe Islands
                                  </option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">
                                    French Guiana
                                  </option>
                                  <option value="French Polynesia">
                                    French Polynesia
                                  </option>
                                  <option value="French Southern Ter">
                                    French Southern Ter
                                  </option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">
                                    Great Britain
                                  </option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">
                                    Isle of Man
                                  </option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">
                                    Korea North
                                  </option>
                                  <option value="Korea Sout">
                                    Korea South
                                  </option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">
                                    Liechtenstein
                                  </option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">
                                    Marshall Islands
                                  </option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">
                                    Midway Islands
                                  </option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">
                                    Netherland Antilles
                                  </option>
                                  <option value="Netherlands">
                                    Netherlands (Holland, Europe)
                                  </option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">
                                    New Caledonia
                                  </option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">
                                    Norfolk Island
                                  </option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">
                                    Palau Island
                                  </option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">
                                    Papua New Guinea
                                  </option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">
                                    Philippines
                                  </option>
                                  <option value="Pitcairn Island">
                                    Pitcairn Island
                                  </option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">
                                    Puerto Rico
                                  </option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">
                                    Republic of Montenegro
                                  </option>
                                  <option value="Republic of Serbia">
                                    Republic of Serbia
                                  </option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">
                                    St Barthelemy
                                  </option>
                                  <option value="St Eustatius">
                                    St Eustatius
                                  </option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">
                                    St Kitts-Nevis
                                  </option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">
                                    St Pierre & Miquelon
                                  </option>
                                  <option value="St Vincent & Grenadines">
                                    St Vincent & Grenadines
                                  </option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">
                                    Samoa American
                                  </option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">
                                    Sao Tome & Principe
                                  </option>
                                  <option value="Saudi Arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">
                                    Sierra Leone
                                  </option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">
                                    Solomon Islands
                                  </option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">
                                    South Africa
                                  </option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">
                                    Switzerland
                                  </option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">
                                    Trinidad & Tobago
                                  </option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">
                                    Turkmenistan
                                  </option>
                                  <option value="Turks & Caicos Is">
                                    Turks & Caicos Is
                                  </option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">
                                    United Kingdom
                                  </option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">
                                    United Arab Emirates
                                  </option>
                                  <option value="United States of America">
                                    United States of America
                                  </option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">
                                    Vatican City State
                                  </option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">
                                    Virgin Islands (Brit)
                                  </option>
                                  <option value="Virgin Islands (USA)">
                                    Virgin Islands (USA)
                                  </option>
                                  <option value="Wake Island">
                                    Wake Island
                                  </option>
                                  <option value="Wallis & Futana Is">
                                    Wallis & Futana Is
                                  </option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                               
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-mob">
                              <div>
                                <button
                                  type="submit"
                                  class="btn btn_primary primary-btn primary1 submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>{" "}
                        <div id="submitText"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Contact</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  <a href="mailto:hello@trulydesignfirm.com">
                    <h2>hello@trulydesignfirm.com</h2>
                  </a>

                  <a href="tel:+91 9059028599">
                    <h2 className="call_n">+91 9059028599</h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  */}
        <section class="mission what-we">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header">
                  <p>Address</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header hiring_header">
                  <h2>Hyderabad</h2>
                </div>
                <div class="mission_paras hiring_header">
                  <p>
                    H.No. 2-50/16, Yasaswini Residency N.Qube Homes, 4th Floor,
                    Khanamet, Madhapur, Hyderabad, Telangana 500081.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br> Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    <a href="" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default contact;

// console.log(data)
// $.ajax({
//     type: "POST",
//     url: "https://dev-api.onoark.com/v1/admin/website/contact-us",
//     headers: { 'Content-Type': 'application/json' },
//     data: JSON.stringify(formData),
//     success: function (response) {
//     console.log("response",response)
//       // $("#basic-form")[0].reset();
//     alert('Successfully uploaded your details!');
//     // document.getElementById("basic-form").reset();

//   },
//   error: function (errors) {
//       // console.log(data);
//     alert('Failed to upload details!');
//   },
// });
