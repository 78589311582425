import React, { Component } from "react";

import Route from "react-router-dom/Route";

import { NavLink } from "react-router-dom";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import about from "./Components/about";
import example from "./Components/example";
import what_we_do from "./Components/what_we_do";
import manifesto from "./Components/manifesto";
import contact from "./Components/contact";
import process from "./Components/process";
import resources from "./Components/resources";
import helloSummary from "./Components/helloSummary";
import works from "./Components/works";
import testimonials from "./Components/testimonials";
import clients from "./Components/clients";
import washhit from "./Components/washhit";
import branding from "./Components/branding";
import ux_design from "./Components/ux_design";
import custom_design from "./Components/custom_design";
import terms_and_conditions from "./Components/terms_and_conditions";
import design_that_speaks_eyes from "./Components/design_that_speaks_eyes";
import conversational_uxdesign from "./Components/conversational_uxdesign";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// import * as $ from 'jquery';
import notfound from "./Components/notfound";
import ScrollToTop from "./Components/ScrollToTop";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";

import { Link } from "react-router-dom";
import truly_logo from "./assets/img/home/truly_logo.svg";
import menu from "./assets/img/home/menu.svg";
import c from "./assets/img/home/c.svg";
import what from "./assets/img/what.svg";
import * as $ from "jquery";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeNavigation: false,
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      if (location.pathname === "/") {
        this.setState({ changeNavigation: false });
        console.log(this.state.changeNavigation);
      }
      if (location.pathname !== "/") {
        this.setState({ changeNavigation: true });
        console.log(this.state.changeNavigation);
      } else {
        this.setState({ getintouchText: "Talk to us now!" });
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    // var activeNavItem = $('.nav-link');

    // activeNavItem.click(function(){
    //   activeNavItem.removeClass('active');
    //   $(this).addClass('active');
    // });
    if (this.props.history.location.pathname == "/") {
      // activeNavItem.removeClass('active');
      console.log("remove");
      this.setState({ changeNavigation: false });
      console.log(this.state.changeNavigation, "reload");
    }
    if (this.props.history.location.pathname !== "/") {
      this.setState({ changeNavigation: true });
      console.log(this.state.changeNavigation, "reload");
    }
    // function resizeHeaderOnScroll() {
    //   const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    //   shrinkOn = 200,
    //   headerEl = document.querySelector('.header1');

    //   if (distanceY > shrinkOn) {
    //     headerEl.classList.add("smaller");
    //   } else {
    //     headerEl.classList.remove("smaller");
    //   }
    // }

    // window.addEventListener('scroll', resizeHeaderOnScroll);

    // navigation on click //

    $(document).on("click", 'a[href^="#shop"]', function (event) {
      // event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 120,
        },
        1300
      );
    });

    $(document).on("click", 'a[href^="#courses"]', function (event) {
      // event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 120,
        },
        1300
      );
    });
    $(document).on("click", 'a[href^="#community"]', function (event) {
      // event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 90,
        },
        1300
      );
    });
    $(document).on("click", 'a[href^="#hiredesigner"]', function (event) {
      // event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 100,
        },
        1300
      );
    });
    $(document).on("click", 'a[href^="#learn"]', function (event) {
      // event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 100,
        },
        1300
      );
    });
    $(document).on("click", 'a[href^="/learn"]', function (event) {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 100,
        },
        1300
      );
    });
    //   function showHeader() {
    //     var sticky = $('.header1'),
    //     scroll = $(window).scrollTop();
    //     if (scroll >= 100) sticky.addClass('header_area1');
    //     else sticky.removeClass('header_area1');
    // }
    // $(window).scroll(function () {

    //     showHeader();
    //     });
    if ($(window).width() < 991) {
      $(".navbar-toggler1").click(function () {
        $("#navbarSupportedContent1").addClass("menu");
      });
      $(".closebtn").click(function () {
        $("header").css("z-index", "99");
        $("#navbarSupportedContent1").removeClass("menu");
      });
      $(".nav-link1").click(function () {
        $("header").css("z-index", "99");
        $("#navbarSupportedContent1").removeClass("menu");
      });
      $(".navbar-toggler1").click(function () {
        $("#navbarSupportedContent1").removeClass("header");
      });
    }
  }

  render() {
    return (
      <ScrollToTop>
        <div className="App">
          <Helmet>
            <title>Best Designing Company in India | Mobile App UX Design in India | Truly</title>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Truly design firm is the best company for branding & website design in India. One of the most renowned organization for UI/UX design services in India."
            />
           
          </Helmet>

          <div>
            {/* {this.state.changeNavigation == false?  */}
            <section>
              <div class="header1">
                <div class="">
                  <div class="main_menu1">
                    <div class="container">
                      <nav class="navbar navbar-expand-lg justify-content-center">
                        <Link exact={true} to="/">
                          <a class="navbar-brand" href="">
                            <img src={truly_logo} class="brand_logo" />
                          </a>
                        </Link>

                        <div class="navbar-toggler1">
                          <img src={menu} />
                        </div>

                        <div
                          class="menu-sec justify-content-around"
                          id="navbarSupportedContent"
                        >
                          <a
                            href="javascript:void(0)"
                            class="closebtn"
                            onclick="closeNav()"
                          >
                            <img src={c} alt="close" />
                          </a>
                          <ul class="navbar-nav main-navbar justify-content-center">
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/what-we-do"
                                class="nav-link  nav-link1"
                              >
                                What We Do
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/about"
                                class="nav-link  nav-link1"
                              >
                                about us
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/works"
                                class="nav-link  nav-link1"
                              >
                                {" "}
                                Works
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/process" class="nav-link  nav-link1">
                                The Process
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/manifesto" class="nav-link  nav-link1">
                                Manifesto
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/resources" class="nav-link  nav-link1">
                                resources
                              </Link>
                            </li>
                            <li class="nav-item">
                              {/* <Link to="/contact" class="nav-link nav-link1">
                          Contact Us
                          </Link> */}
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                                class="nav-link  nav-link1"
                                target="_blank"
                              >
                                contact us
                              </a>
                            </li>
                          </ul>
                          <ul class="navbar-nav main-navbar justify-content-end"></ul>
                        </div>
                        <div class="menu-sec" id="navbarSupportedContent1">
                          <a
                            href="javascript:void(0)"
                            class="closebtn"
                            onclick="closeNav()"
                          >
                            <img src={c} />
                          </a>
                          <ul class="navbar-nav ml-auto main-navbar">
                            {/* <li class="nav-item">
                              <Link to="/about" class="nav-link  nav-link1">
                                about us
                              </Link>
                            </li> */}
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/what-we-do"
                                class="nav-link  nav-link1"
                              >
                                What We Do
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/about"
                                class="nav-link  nav-link1"
                              >
                                about us
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                activeClassName="is-active"
                                to="/works"
                                class="nav-link  nav-link1"
                              >
                                {" "}
                                Works
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/process" class="nav-link  nav-link1">
                                The Process
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/manifesto" class="nav-link  nav-link1">
                                Manifesto
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/resources" class="nav-link  nav-link1">
                                resources
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link to="/clients" class="nav-link  nav-link1">
                                clients
                              </Link>
                            </li>
                            <li class="nav-item">
                              <Link
                                to="/testimonials"
                                class="nav-link  nav-link1"
                              >
                                Testimonials
                              </Link>
                            </li>
                            <li class="nav-item">
                              {/* <Link to="/contact" class="nav-link  nav-link1">Contact Us</Link> */}
                              <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                                class="nav-link  nav-link1"
                                target="_blank"
                              >
                                Contact us
                              </a>

                              {/* <a href="https://wa.me/919059028599" class="nav-link  nav-link1" target="_blank">contact us</a> */}
                            </li>
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* : */}
            {/* <section>
                <div class="header1">
            <div class="">
              <div class="main_menu1">
            <div class="container">
                        <nav class="navbar navbar-expand-lg">
                        <Link to="/"><a class="navbar-brand" href=""><img src={truly_logo} class="brand_logo"/></a></Link>
                      
                          <div class="navbar-toggler1"><i class="fa fa-bars" aria-hidden="true"></i></div>
                          
                              <div class="menu-sec" id="navbarSupportedContent">
                                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                                <ul class="navbar-nav ml-auto main-navbar">
                      
                      <li class="nav-item">
                      <Link to="/what-we-do" class="nav-link  nav-link1">What We Do</Link>
                      </li>
                      <li class="nav-item">
                      <Link to="/works" class="nav-link  nav-link1"> Works</Link>
                        </li>
                        <li class="nav-item">
                        <Link to="/process" class="nav-link  nav-link1">The Process</Link>
                        </li>
                        <li class="nav-item">
                        <Link to="/manifesto" class="nav-link  nav-link1">Manifesto</Link>
                       </li>
                       <li class="nav-item">
                        <Link to="/resources" class="nav-link  nav-link1">resources</Link>
                        </li>
                        <li class="nav-item">
                        <Link to="/contact" class="nav-link  nav-link1">Contact Us</Link>
                        
                       </li>
                      
                         </ul>
                           
                            </div>
                          </nav>
                          </div>
                </div>
                    </div></div>
            </section> */}
            {/* } */}
          </div>

          <Switch>
            <Route exact path="/" exactly component={Home} />
            <Route exact path="/about" exactly component={about} />
            <Route exact path="/example" exactly component={example} />
            <Route exact path="/what-we-do" exactly component={what_we_do} />
            <Route exact path="/manifesto" component={manifesto} />
            <Route exact path="/process" component={process} />
            <Route exact path="/contact" component={contact} />
            <Route exact path="/resources" component={resources} />
            <Route exact path="/helloSummary" component={helloSummary} />
            <Route exact path="/works" component={works} />
            <Route exact path="/testimonials" component={testimonials} />
            <Route exact path="/clients" component={clients} />
            <Route exact path="/branding" component={branding} />
            <Route exact path="/ux-design" component={ux_design} />
            <Route exact path="/custom-design" component={custom_design} />
            <Route
              exact
              path="/conversational-uxdesign"
              component={conversational_uxdesign}
            />
            <Route
              exact
              path="/design-that-speaks-eyes"
              component={design_that_speaks_eyes}
            />
            <Route exact path="/washhit" component={washhit} />
            <Route
              exact
              path="/terms-and-conditions"
              component={terms_and_conditions}
            />
            {/* <Route component={() => (<div>404 Not found </div>)} /> */}
            <Route path="" component={notfound} />
          </Switch>

          <Footer />
          <a
            href="https://wa.me/9059028599"
            className="whatsapp"
            target="_blank"
          >
            <img src={what} alt="image" className="" />
          </a>
        </div>
      </ScrollToTop>
    );
  }
}

// npm install -g serve

// serve -s build

export default withRouter(App);
