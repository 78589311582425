import React, { Component } from "react";

import wash_small from "../assets/img/home/wash_small.svg";
import work_img1 from "../assets/img/home/work_img1.png";
import work_img2 from "../assets/img/home/work_img2.png";
import arrow_black from "../assets/img/home/arrow_black.svg";
import works_bnr from "../assets/img/home/works_bnr.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import Ono1 from "../assets/img/works/Ono1.png";
import Ono from "../assets/img/works/Ono.png";
import Adtru from "../assets/img/works/Adtru.png";
import Actly from "../assets/img/works/Actly.png";
import Adtru1 from "../assets/img/works/Adtru1.png";
import Df1 from "../assets/img/works/Df1.png";
import Df2 from "../assets/img/works/Df2.png";
import Ditebowl1 from "../assets/img/works/Ditebowl1.png";
import Ditebowl from "../assets/img/works/Ditebowl.png";
import Washhit1 from "../assets/img/works/Washhit1.png";
import Washhit2 from "../assets/img/works/Washhit2.png";
import Corecapital1 from "../assets/img/works/Corecapital1.png";
import Corecapital from "../assets/img/works/Corecapital.png";
import Merrito from "../assets/img/works/Merrito.png";
// import  Rawfood1  from '../assets/img/works/Rawfood1.png';
import Rawfood from "../assets/img/works/Rawfood.png";
import Ubcas from "../assets/img/works/Ubcas.png";
import uandi from "../assets/img/works/uandi.png";
import Sreedhartruly from "../assets/img/works/Sreedhartruly.png";
import Sreedhartruly1 from "../assets/img/works/Sreedhartruly1.png";
import pgo from "../assets/img/works/pgo.png";
import hepc from "../assets/img/works/hepc.png";
import bw from "../assets/img/works/bw.png";
import ideeo from "../assets/img/works/ideeo.png";
import botjet from "../assets/img/works/botjet.png";
import Niclyo from "../assets/img/works/Niclyo.png";
import Niclyo1 from "../assets/img/works/Niclyo1.png";
import Nextergo from "../assets/img/works/Nextergo.png";
import Nextergo1 from "../assets/img/works/Nextergo1.png";
import Evaa from "../assets/img/works/Evaa.png";
import Evaa1 from "../assets/img/works/Evaa1.png";
import Artlover from "../assets/img/works/Artlover.png";
import Covnia from "../assets/img/works/Covnia.png";
import starfire from "../assets/img/works/starfire.png";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class works extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
               
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img src={works_bnr} class="expert-bnr" alt="image" />
                </figure>
                <div className="d-flex align-items-end justify-content-between">
                <div class="banner_text banner_mob_text">
                  <h1>TRULY Works</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Connecting your Brand to People</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>Connecting your Brand<br></br> to People</h3>
                  </figure>

                  {/* <Link to="/contact"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="image" />
                    </button>
                    </a>
                  {/* </Link> */}
                </div>
                <figure class="d-none d-sm-none d-md-block">
                  <img src={works_bnr} class="expert-bnr" alt="image" />
                </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="learn_things " id="shop">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-3">
                <div class="section-header">
                  <p class="">Washh it</p>
                </div>
              </div>

              <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
                {/* <!-- Tab panes --> */}
                <div class="tab-content">
                  <div class="tab-pane active" id="home-v">
                    <figure class="d-block d-sm-block d-md-none">
                      <div class="section-header learn_head mt-3">
                        <h2>
                          Let our Designs Speak for us. Check out our Case
                          Studies.
                        </h2>
                      </div>
                    </figure>
                    <figure class="d-none d-sm-none d-md-block">
                      <div class="section-header ">
                        <h2>
                          Let our Designs Speak for us. <br></br> Check out our
                          Case Studies.
                        </h2>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows project_rows1">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <a href="https://www.onoark.com/" target="_blank">
                      <div className="card">
                        <OwlCarousel
                          items={1}
                          dots={true}
                          className="owl-theme product_images project_images"
                          loop
                          margin={40}
                        >
                          <div>
                            <img src={Ono} className="img-fluid" alt="image" />
                          </div>
                          <div>
                            <img src={Ono1} className="img-fluid" alt="image" />
                          </div>
                        </OwlCarousel>
                        <div className="card-body px-0 pb-0">
                          <h4>ono</h4>
                          <div className="card-head">
                            <h6 className="work_head">
                              Branding & identity | website design product UI UX
                              design | Graphic design services
                            </h6>
                          </div>
                          <div className="card-text mt-0 mb-0">
                            <p className="mb-0">
                              ONO provides real-time information of mandi prices
                              of many crops so that farmers are better informed
                              and ultimately gain good profits or mitigate huge
                              losses. Our end to end UI UX design took care of
                              all the digital presence of the service.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card ">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Adtru} className="img-fluid" alt="image" />
                        </div>
                        <div>
                          <img src={Adtru1} className="img-fluid" alt="image" />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>adtru</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ADTRU advertising agency covers all forms of offline
                            promotion such as Hoardings, Car and Auto ads and
                            more. With our thoughtful design, we established
                            their visual identity and conveyed their intent
                            through posters for publicity and advertising.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card ">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images small_img"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Ditebowl}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div>
                          <img
                            src={Ditebowl1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>diet bowl</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Diet bowl provides pure organic Cow Ghee that are
                            useful for various purposes. Our{" "}
                            <span className="text-uppercase">UI UX</span> Design
                            agency covered all the aspects of Branding &
                            Identity and designing posters.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xs-12 ">
                    <a href="https://deepforgeai.com/" target="_blank">
                      <div className="card">
                        <OwlCarousel
                          items={1}
                          dots={true}
                          className="owl-theme product_images project_images"
                          loop
                          margin={40}
                        >
                          <div>
                            <img src={Df1} className="img-fluid" alt="image" />
                          </div>
                          <div>
                            <img src={Df2} className="img-fluid" alt="image" />
                          </div>
                        </OwlCarousel>

                        <div className="card-body px-0 pb-0">
                          <h4>deepforge</h4>
                          <div className="card-head">
                            <h6 className="work_head">
                              Branding & Identity | Publicity and Advertising
                              Poster Designing
                            </h6>
                          </div>
                          <div className="card-text mt-0 mb-0">
                            <p className="mb-0">
                              Deepforge AI helps businesses to integrate one of
                              the most important and highly expected
                              functionality, conversational user experience in
                              their websites or mobile apps. We provided them
                              the critical Branding & identity services and
                              designed posters for publicity and advertising.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={Ubcas} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>ubcas</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Stationery Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            UBCAS is an IT consulting service that empowers
                            various businesses. Our design firm established
                            their visual identity with branding and worked on a
                            set of stationary designs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={starfire} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>Starfire </h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Jewellery Thoughts | Printed
                            Collateral
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Starfire is an e-commerce website that sells various
                            jewellery. In Addition to the Branding & Poster
                            designing services, we also provided new jewellery
                            thoughts and printed collateral.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card mb-0 small_img">
                      <img src={Merrito} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>merrito polimers</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Stationery Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Merrito polymers provide best in class pvc films for
                            use in food & pharmaceutical industries. We, at
                            truly design firm, provided them with all the
                            essential design services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows section_row">
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Nextergo}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div>
                          <img
                            src={Nextergo1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>
                      <div className="card-body px-0 pb-0">
                        <h4>nextergo</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design{" "}
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Nextergo provides standing desks that are truly
                            smart and have a lot of features. Our{" "}
                            <span className="text-uppercase">UI UX</span> design
                            firm designed all of the branding & identity and
                            other aspects of digital presence.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <div>
                        <img src={hepc} className="img-fluid" alt="image" />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>Happy Eagle Pest Control</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Stationery Design| Stationery
                            Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Happy Eagle provides dependable pest control
                            services that renders the fields free of pests. We
                            provided comprehensive digital and also physical
                            designs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <div>
                        <img src={Rawfood} className="img-fluid" alt="image" />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>Rawfood</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Stationery Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            RAWFOOD provides various organic food items to their
                            customers. We rendered them our design services in
                            various aspects.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xs-12 ">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Evaa} className="img-fluid" alt="image" />
                        </div>
                        <div>
                          <img src={Evaa1} className="img-fluid" alt="image" />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>evaa</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            EVAA provides a digital management system that
                            simplifies organization and management that
                            effectively helps in measuring work and improving
                            the value of it. EVAA availed complete end to end
                            design services from us.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={Artlover} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>artlover</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Stationery Design | Packaging
                            Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ARTLOVER provides numerous collections for
                            presentations and also custom gifts that work well
                            for a lot of occasions. Our TRULY DESIGN FIRM
                            provided end to end design services for ARTLOVER.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={Covnia} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>covnia</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            COVNIA is a secure and intuitive video calling and
                            video conferencing solution. Our{" "}
                            <span className="text-uppercase">UI UX</span> design
                            firm carefully crafted all of their digital presence
                            and experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card mb-0 small_img">
                      <img src={uandi} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>u & i</h4>
                        <div className="card-head">
                          <h6 className="work_head">Branding & Identity</h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            U & I is a movie production company. We have
                            provided them with our Branding & Identity services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-8 col-md-8 col-xs-12 ">
                    <a href="https://sreedhartruly.com/" target="_blank">
                      <div className="card">
                        <OwlCarousel
                          items={1}
                          dots={true}
                          className="owl-theme product_images project_images"
                          loop
                          margin={40}
                        >
                          <div>
                            <img
                              src={Sreedhartruly}
                              className="img-fluid"
                              alt="image"
                            />
                          </div>
                          <div>
                            <img
                              src={Sreedhartruly1}
                              className="img-fluid"
                              alt="image"
                            />
                          </div>
                        </OwlCarousel>

                        <div className="card-body px-0 pb-0">
                          <h4>Sreedhar truly</h4>
                          <div className="card-head">
                            <h6 className="work_head">
                              Personal Branding & Identity{" "}
                            </h6>
                          </div>
                          <div className="card-text mt-0 mb-0">
                            <p className="mb-0">
                              SREEDHER TRULY is a personal website that
                              showcases the work and credibility of the
                              individual. We have provided him the design that
                              optimally tells his story and skills.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <div>
                        <img src={Actly} className="img-fluid" alt="image" />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>actly</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Mobile App Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span>
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ACTLY is an online learning platform that contains a
                            lot of courses for film enthusiasts. Our design firm
                            developed and designed not only their Branding &
                            identity, but also product{" "}
                            <span className="text-uppercase">UI UX</span> along
                            with mobile app.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* hh */}

                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={ideeo} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>IDEEO Tech Solutions</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            IDEEO had unique requirements that needed custom
                            service. We worked with the IDEEO team on every
                            aspect and fulfilled their design needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <img src={bw} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>body workout</h4>
                        <div className="card-head">
                          <h6 className="work_head">Branding & Identity</h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            BODY WORKOUT is a gym and fitness center. Our
                            branding & identity services gave them a unique
                            identity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card mb-0 small_img">
                      <img src={botjet} className="img-fluid" alt="image" />

                      <div className="card-body px-0 pb-0">
                        <h4>botjet ai</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Botjet AI designs conversational AI for businesses
                            over any medium including Web, IoT and mobiles. We
                            provided them end to end design services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows">
                  {/* <OwlCarousel items={1}  className="owl-theme product_images what_we_do_c" loop margin={40} >   */}

                  {/* </OwlCarousel> */}
                  <div className="col-lg-8 col-md-8 col-xs-12 ">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img src={Niclyo} className="img-fluid" alt="image" />
                        </div>
                        <div>
                          <img
                            src={Niclyo1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>Nuclyo</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & Identity | Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Nuclyo is a digital management system designed for
                            community management. We worked on their entire
                            design system spanning from branding to their
                            product{" "}
                            <span className="text-uppercase">UI UX</span> and
                            website.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <div>
                        <img src={pgo} className="img-fluid" alt="image" />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>gopgo</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            {" "}
                            Branding & Identity | Publicity and Advertising
                            Poster Designing | Product{" "}
                            <span className="text-uppercase">UI, UX</span> |
                            Website Design
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            GO PGO helps their customers to effortlessly find
                            places for them to live in cities. Our design firm
                            rendered end to end design services for them.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row work_row customer-row project_rows section_row">
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Corecapital}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div>
                          <img
                            src={Corecapital1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>Core capital</h4>
                        <div className="card-head">
                          <h6 className="work_head"> Branding & Identity </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            Core capital provides real estate consultancy
                            services for their clients. We designed the visual
                            identity for their brand and provided other branding
                            services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-xs-12">
                    <a href="https://washhit.com/" target="_blank">
                      <div className="card">
                        <OwlCarousel
                          items={1}
                          dots={true}
                          className="owl-theme product_images project_images"
                          loop
                          margin={40}
                        >
                          <div>
                            <img
                              src={Washhit1}
                              className="img-fluid"
                              alt="image"
                            />
                          </div>
                          <div>
                            <img
                              src={Washhit2}
                              className="img-fluid"
                              alt="image"
                            />
                          </div>
                        </OwlCarousel>
                        <div className="card-body px-0 pb-0">
                          <h4>washhit</h4>
                          <div className="card-head">
                            <h6 className="work_head">
                              Branding & Identity | Publicity and Advertising
                              Poster Designing | Product UI, UX | Website Design
                            </h6>
                          </div>
                          <div className="card-text mt-0 mb-0">
                            <p className="mb-0">
                              Washh it provides comprehensive dry cleaning &
                              laundry services including pickup and delivery
                              times that suit their customers. We provided
                              complete design services for them.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="learn_things pt-0" id="shop">
  <div class="container">
    <div class="row">
    <div class="col-lg-3 col-md-3 col-xs-3">
      
      <div class="section-header">
        <p class="">Washh it</p>
       </div>
</div>

      <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
       
        <div class="tab-content">
          <div class="tab-pane active" id="home-v">
            <div class="section-header learn_head">
              <div>
               <h2>Let our designs speak for us. 
 </h2>
               <h2> Check out our case studies.</h2>
              </div>
             
             </div>
           
          </div>
        
      
       
        </div>
        <div className="work_row customer-row">
         <div className="card">
       
      <img src={work_img1}alt="image" />
     
       <div className="card-body px-0 pb-0">
         <div className="card-head">
        <h6 className="work_head">Provided services for washhit Branding & Identity | Website Design Publicity Product UX Design | Business Strategy</h6>
         </div>
         <div className="card-text mt-0 mb-0">
           <p className="mb-0">Laundry is a big problem for all the householders, Business people, bachelors etc to all the people out there. We have come up with a solution by introducing our new product Washiit.</p>
         </div>
       </div>
        </div>
     
        </div>
      
      </div>
</div>

  </div>
</section> */}
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default works;
