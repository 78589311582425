import React, { Component } from "react";

import welcomeBlog1 from "../assets/img/home/welcomeBlog1.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import uxblogbanner1 from "../assets/img/home/uxblogbanner1.svg";
import uxblogimg2 from "../assets/img/home/uxblogimg2.svg";
import designEyesblog1 from "../assets/img/home/designEyesblog1.png";
import back_arrow from "../assets/img/home/back_arrow.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class conversational_uxdesign extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner pb-0">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-12 col-xs-12 px-0">
                <div class="main-txt innerBlog_txt">
                  <div>
                    <img src={uxblogbanner1} alt="blog_banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <Link to="/resources">
                    {" "}
                    <p>
                      <img src={back_arrow} className="mr-2"></img>Back
                    </p>
                  </Link>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header ">
                  <h4>JUNE 30, 2020 | AUTHOR TRULY DESIGN FIRM</h4>
                  <h2>Enhancing The User Experience Of Chatbots </h2>
                  {/* <h2>Brief Introduction Of Your Company?</h2> */}
                </div>
                <div class="mission_paras">
                  <p>
                    The Role of User Experience is indispensable in the success
                    of any application. All the great features of your product
                    would not be “great” if they are not usable!{" "}
                  </p>
                  <p>
                    In the course of action for improving user experience, many
                    businesses are implementing chatbots. Indeed, chatbots have
                    a myriad of advantages: giving personalized responses,
                    reducing the work users need to do for getting the
                    information they need, and others. Also, advances in AI
                    brought improvements in conversational bots for solving
                    human needs.
                  </p>
                  <p>
                    But, you can’t just include any kind of chatbot and get it
                    over with thinking that you have perfected your experience!
                    While it is certainly true that chatbots improve User
                    Experience… if they are not properly designed the very same
                    chatbot can hurt it.
                  </p>
                  <p>
                    Remember that great features aren’t great if they are not
                    usable in an intended way? if your chatbot which is supposed
                    to solve <span className="text-uppercase">UX</span> issues
                    itself has <span className="text-uppercase">UX</span>{" "}
                    issues, then you are back to square one!
                  </p>
                  <p>
                    Hello! We are Truly design firm and in this guide, we share
                    some insights as to what goes into designing a great
                    conversational user experience.
                  </p>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Start with Why</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      As with any endeavor, starting with why eliminates a lot
                      of confusion and helps to set a proper goal. Ask yourself
                      what are the specific needs of your customers you are
                      going to tackle with your chatbot. If you already have
                      customers, you can begin requirement elicitation and
                      validation from the start! Try to find out if they would
                      use a chatbot for the tasks that you want automated.
                    </p>
                    <p>
                      It is also important to define use cases and how they
                      unfold. This helps you to put your chatbot into context
                      and provide necessary information or service for helping
                      your users.
                    </p>
                  </div>
                </div>

                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      Maintain Consistency And Usability Throughout the Dialogue{" "}
                    </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Your first message should clearly mention about you and
                      what the user can expect out of the bot and make sure each
                      answer is complete and leads to the next appropriate
                      question. Also, showing what the bot is doing in the
                      background will help people in understanding what is going
                      on which is a lot better than just leaving them wondering
                      if your bot had even understood their query!
                    </p>
                    <p>
                      Getting your end right is as important as the start and
                      continuation. In Case your bot fails to solve the problem
                      of the user, give them any additional links or link them
                      to your representatives for further solutions. Also, Users
                      in either case of being their objectives accomplished or
                      not would have valuable feedback for you. Ask them to
                      provide feedback that you can use for further development.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>Aim for Continuous Improvement </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      You might not get everything correct for the first time.
                      By continuously iterating the above processes all while
                      incorporating feedback from your customers, you need to
                      continuously improve your chatbots and reach perfection!
                    </p>
                    <p>
                      With developments of new technology, making and developing
                      chatbots are easier. A report by Grand View Research
                      expected that the chatbot market could go by $1.2 billion
                      by the next decade! In Addition to the text-based
                      chatbots, automated call centers are also going to rise!
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header  blog_header">
                    <h2>
                      Truly’s Experience with Designing Conversational{" "}
                      <span className="text-uppercase">UX</span> — Nuacem{" "}
                    </h2>
                  </div>
                  <div className="mission_paras">
                    <p>
                      {" "}
                      <img
                        src={uxblogimg2}
                        alt="img"
                        className="welcome_img1"
                      />
                    </p>
                  </div>
                  <div className="mission_paras">
                    <p>
                      Our experience with designing conversational{" "}
                      <span className="text-uppercase">UX</span> happened with
                      Nuacem, a company that develops a suite of conversational
                      bots. We helped Nuacem with product design for
                      conversational user experience, helped them in branding &
                      identity design, and also built their website. They are
                      happy with us and are satisfied. Having satisfied and
                      happy customers are our common goals indeed!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission branding-sec pt-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>RECENT BLOGS</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 p-0 about_col">
                <div class="row blog_row">
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/helloSummary">
                      <div class="card">
                        <div class="card-body">
                          <img src={welcomeBlog1} alt="blog_image" />
                          <h5>
                            Hello! Why don’t we start with a brief introduction
                            of your company?
                          </h5>
                          <p>
                            Hello!<br></br> Truly is a branding and Product{" "}
                            <span className="text-uppercase">UX/UI</span> Design
                            Studio that helps businesses accomplish their
                            objectives.
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-xs-12">
                    <Link to="/design-that-speaks-eyes">
                      <div class="card">
                        <div class="card-body">
                          <img src={designEyesblog1} alt="blog_image" />
                          <h5>Design that speaks to the eyes</h5>
                          <p>
                            Design is truly a visual voice that speaks to the
                            eyes… that communicates your brand to your
                            consumers.{" "}
                          </p>
                        </div>
                        <div className="card_footer">
                          <a href="conversational-uxdesign.html">Read more…</a>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>
                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default conversational_uxdesign;
