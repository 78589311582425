import React, { Component } from "react";

import about_bnr from "../assets/img/home/about_bnr.svg";
import accountability from "../assets/img/home/accountability.svg";
import excellence from "../assets/img/home/excellence.svg";
import settle from "../assets/img/home/settle.svg";
import grow from "../assets/img/home/grow.svg";
import adventurous from "../assets/img/home/adventurous.svg";
import ideas from "../assets/img/home/ideas.svg";
import positive from "../assets/img/home/positive.svg";
import passion from "../assets/img/home/passion.svg";
import lion from "../assets/img/home/lion.svg";
import sreedhar from "../assets/img/home/sreedhar.svg";
import sudarshan from "../assets/img/home/sudarshan.svg";
import about_linkidin from "../assets/img/home/about_linkidin.svg";
import team_members from "../assets/img/home/team_members.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class terms_and_conditions extends Component {
  state = {
    responsive: {
      0: {
        // items: 1,
        // stagePadding:0,
        // navText: [
        //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
        //   '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        // ],
      },
      // 450: {
      //     items: 1,
      // },
      // 600: {
      //     items: 1,
      // },
      1000: {
        items: 2.8,
        autoplay: true,
        slideTransition: "linear",
        autoplaySpeed: 3000,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
      },
    },
    // autoHeight : true,
    // transitionStyle:"fade"
  };

  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>terms and conditions</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>terms and conditions</h3>
                  </figure>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-xs-12"></div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    terms &<br></br> conditions
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="mission_paras">
                  <p>
                    Welcome to Truly Design Firm. By using this website
                    (“www.trulydesignfirm.com”), you are agreeing to comply with
                    and be bound by the following terms and conditions of use,
                    which together with our Privacy Policy govern the
                    relationship between you and www.trulydesignfirm.com,
                    provider of the site. The terms “Provider” or “us” or “we”
                    refer to the owner of the site, www.trulydesignfirm.com,
                    whose registered office is Truly Design Firm, h.no. 2-50/16,
                    yasaswini residency n.qube homes, 4th floor, khanamet,
                    madhapur, hyderabad, telangana 500081. The term “you” refers
                    to the user or viewer of our Site.{" "}
                  </p>
                </div>
                <div className="about_paras">
                  <figure class="d-block d-sm-block d-md-none">
                    <div class="section-header ">
                      <h2>
                      The use of the Site is Subject to the following Terms of
                        Use
                      </h2>
                    </div>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <div class="section-header ">
                      <h2>
                        The use of the site is Subject to <br></br> the
                        following Terms of Use
                      </h2>
                    </div>
                  </figure>

                  <div class="mission_paras">
                    <p>
                      1.The content of the pages of this site and courses
                      available on the site is for your general information and
                      use only. It is subject to change without notice.
                    </p>
                    <p>
                      2.Your use of any information or materials on the Provider
                      Site is entirely at your own risk, for which we shall not
                      be liable. It shall be your own responsibility to ensure
                      that any products, services, or information available
                      through this Site meet your specific requirements.
                    </p>
                    <p>
                      3.The Trademark, Logos, and service marks (“marks”)
                      displayed on the site are the property of the Provider and
                      other parties. You are prohibited from using any Marks for
                      any purpose including, but not limited to use as metatags
                      on any other pages or Sites on the World Wide Web without
                      the written permission of the Provider or such third party
                      which may own the Marks. All information and content
                      located on the Site are protected by Copyright. You are
                      prohibited by modifying, copying, distributing,
                      transmitting, displaying, publishing, selling, licensing,
                      creating derivative works, or using any Content available
                      on or through the Site for a commercial or public purpose.
                      Unauthorized use of the Provider Site may give rise to a
                      claim for damages and/or be a criminal offense.
                    </p>
                    <p>
                      4.This Site provides links to other Sites by allowing you
                      to leave this Site to access third-party material or by
                      bringing third-party material into this Site via “inverse”
                      hyperlinks and framing a technology (a “Linked Site”). The
                      provider has no discretion to alter, update, or control
                      the content of a linked site. The fact that Provider has
                      provided a link to a Site is not an endorsement,
                      authorization, sponsorship, or affiliation with respect to
                      such Site, its owners, or its provides. There are inherent
                      risks in relying upon, using, or retrieving any
                      information found on the internet, and Provider urges you
                      to make sure you understand these risks before relying
                      upon, using, or retrieving any such information on a
                      linked site.
                    </p>
                    <p>
                      5.All contents, products, and services on the site, or
                      obtained from a Site to which the Site is linked (a
                      “Linked Site”) are provided to you “AS IS” without
                      warranty of any kind either express or implied including,
                      but not limited to, the implied warranties and
                      merchantability and fitness for a particular purpose,
                      title, non-infringement, security or accuracy.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>1. Standard Terms and Conditions</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      The challenges and constraints of today’s fast-moving
                      market are something that comes when you own an agency.
                      Our strong ambition and result-oriented attitude to
                      finding solutions are what keeps our attitude
                      entrepreneurial.{" "}
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>2. Our Fees and Agreement Duration </h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      The Project is valid for the next 2 months. Payment is
                      made in 3 Phases. Phase 1: 30% upfront to start the
                      project. Phase 2: 40% once after the designs have been
                      finalized and to start the project. Phase 3: 30% Post
                      development of the project and before handover. All the
                      source files will be dispatched after the final payment is
                      done. An Advance Payment notification will be sent as soon
                      as the proposal is accepted{" "}
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>3. Supply of Materials</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      You must supply all materials and information required by
                      us to complete the work in accordance with any agreed
                      specification. Such materials may include but are not
                      limited to, photographs, written copy, logos, Source Code
                      and other relevant project materials. Where there is any
                      delay in supplying these materials to us which leads to a
                      delay in the completion of work, we have the right to
                      extend any previously agreed deadlines by a reasonable
                      amount. Where you fail to supply materials, and that
                      prevents the progress of the work, we have the right to
                      invoice you for any part or parts of the work already
                      completed.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>4. Design</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Truly Design Firm will provide design samples and
                      variations depending on the client. This is customizable
                      for each individual client which will be discussed during
                      the demo. Any changes beyond the initially discussed ones
                      will be provided at an extra cost which will be set after
                      a discussion between both the parties. This is
                      irrespective of whether it is utilized or not.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>5. Changes</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Truly Design Firm encourages inputs from the Client during
                      the design process. Once the design is signed off, any
                      changes to the master design will be charged extra. In
                      case of a request for a change, the client has to notify
                      Truly Design Firm via an email and list down the requested
                      changes. Also, a stipulated time period will have to be
                      fixed for the delivery of the updated designs. The
                      following are the list of changes that come under the
                      aforementioned changes to the design: choosing a different
                      font, colors, asking us to follow references and alignment
                      of the arrangement of the layout. All other changes are
                      subject to the discretion of Truly Design Firm
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header headr_web">
                    <h2>6. Project Delays and Client Liability</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Any time frame or estimates that we give are contingent
                      upon your full cooperation and final content in designs
                      for the work pages. During development, there is a certain
                      amount of feedback required in order to progress to
                      subsequent phases. It is required that a single point of
                      contact be appointed from your side and be made available
                      on a daily basis in order to expedite the feedback
                      process.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>7. Approval of Work</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      On completion of the work, you will be notified and have
                      the opportunity to review it. You must notify us in
                      writing of any unsatisfactory points within 7 days of such
                      notification. Any of the work which has not been reported
                      in writing to us as unsatisfactory within the 7-day review
                      period will be deemed to have been approved. Once
                      approved, or deemed approved, work cannot subsequently be
                      rejected and the contract will be deemed to have been
                      completed and the balance of the project price will become
                      due.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>8. Intellectual Property Rights</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      You must obtain all necessary permissions and authorities
                      in respect of the use of all copy, graphic images,
                      registered company logos, names, and trademarks, or any
                      other material that you supply to us to include in your
                      website or web applications. You must indemnify us and
                      hold us harmless from any claims or legal actions related
                      to the content of your website.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>9. Licensing</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Once you have paid us in full for our work we grant you a
                      license to use all the project related material.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>10. Design Credit</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      Client agrees that the website created for the Client by
                      Truly Design Firm may be included in Truly Design Firm’s
                      portfolio
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>11. Subcontracting</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      We reserve the right to subcontract any services that we
                      have agreed to perform for you as we see fit.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>12. Non-Disclosure</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      We (and any subcontractors we engage) agree that we will
                      not at any time disclose any of your confidential
                      information to any third party. You agree to reimburse us
                      for any requested expenses which do not form part of our
                      proposal, including but not limited to the purchase of the
                      templates, third-party software, stock photographs, fonts,
                      and domain name registration, web hosting, or comparable
                      expenses.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>13. Amendments & Addendums</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      This contract is to be considered complete and final.
                      However, the field of marketing and social media is
                      rapidly changing, and said changes may necessitate
                      amendment or addition to this contract. Should such a need
                      arise, the amendment or addendum must be drawn up as a
                      separate document, signed by both parties indicating their
                      agreement, and a copy of the signed document must be
                      provided to the Client and the Consultant.
                    </p>
                  </div>
                </div>
                <div className="about_paras">
                  <div class="section-header ">
                    <h2>14. Governing Law</h2>
                  </div>
                  <div class="mission_paras">
                    <p>
                      This agreement will be governed by the laws of the
                      Telangana state.
                    </p>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default terms_and_conditions;
