import React, { Component } from "react";

import arrow_black from "../assets/img/home/arrow_black.svg";
import branding_process_img from "../assets/img/home/branding_process_img.svg";
import ux_process_img from "../assets/img/home/ux_process_img.svg";
// import arrow_black from '../assets/img/home/arrow_black.svg';
import Sreedhartruly from "../assets/img/works/Sreedhartruly.png";
import Sreedhartruly1 from "../assets/img/works/Sreedhartruly1.png";
import ux_path from "../assets/img/home/ux_path.svg";
import Actly from "../assets/img/works/Actly.png";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class custom_design extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        <Helmet>
          <title>
            {" "}
            The Best SaaS Websites UI/UX DesignServices | trulydesignfirm.com{" "}
          </title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Affordable SaaS website UI/UX DesignServices focused on designing the right online experience for your Enterprises online leads and sales."
          />
          <meta
            name="keywords"
            content="saas website design, saas website design services, saas website uiux design, saas website uiux services, saas graphic design, saasuiux graphic design, saasuiux graphic design services, saas website design, saasux design, saas product design"
          />
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12"></div>
              <div class="col-lg-9 col-md-6 col-xs-12 p-0">
                <div class="banner_text innerBlog_txt">
                  <h1>Custom Design</h1>
                  <h3>Bring us your ideas and we’ll bring them to life</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>INTRODUCTION</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <div class="mission_paras">
                  <p>
                    Got a concept in mind but not a team to execute it? We’re
                    here. A lot of ideas get lost because there was no one to
                    execute them. We are here so that your idea does not get
                    lost. Whether it is jewelry, a product you have in mind, or
                    a restructuring of your website - we do it all.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* banner ends */}
        <section class="mission expertise-cards branding-sec">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    PRODUCT <br></br>DESIGN
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>We make it happen for you</h2>
                </div>

                <div class="card-deck expertise-row">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Product <br></br> Strategy{" "}
                        </h5>
                        <p>
                          Working together with the stakeholders on your team to
                          create the product blueprint and a roadmap.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Scope of <br></br>Product{" "}
                        </h5>
                        <p>
                          Defining the scope of the design which talks about
                          problems, critical touchpoints, features, goals, and
                          solutions for all.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          <span className="text-uppercase">UX</span> <br></br>{" "}
                          Construct{" "}
                        </h5>
                        <p>
                          Building the app by solving all the problems,
                          prioritizing features, and providing a framework that
                          allows iterative development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Prototype <br></br> Design{" "}
                        </h5>
                        <p>
                          One constructed, producing the prototype of the
                          application and making necessary developments by beta
                          testing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Visual <br></br> Design{" "}
                        </h5>
                        <p>
                          Putting together the right colors, fonts, designs, and
                          other relevant graphics in a systematic manner for
                          best results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Print <br></br> Media Design{" "}
                        </h5>
                        <p>
                          A physical entity, say, a brochure holds as much
                          importance as a digital entity holds. They communicate
                          your brand to customers even in your absence while
                          also gaining you important ones.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-deck expertise-row2">
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Acquisition{" "}
                        </h5>
                        <p>
                          Gathering a relevant audience base for your business
                          through various channels by incorporating a
                          systematic, sustainable and adaptable strategy.
                        </p>
                        <ul>
                          <li>1. Brand</li>
                          <li>2. Marketing</li>
                          <li>3. Communication </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Experience{" "}
                        </h5>
                        <p>
                          Building the best experience for your end-users
                          through design and functionality by solving critical
                          problems and reaching all touchpoints.
                        </p>
                        <ul>
                          <li>1. User Research</li>
                          <li>2. Product / Services Definition</li>
                          <li> 3. User Experience Design</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card expert-card2">
                    <div class="card-body">
                      <div class="experts-txt2">
                        <h5>
                          Customer <br></br> Retention{" "}
                        </h5>
                        <p>
                          Retaining customers is more challenging than gaining
                          new customers. Hence, we ensure that your customers
                          are retained.
                        </p>
                        <ul>
                          <li> 1. Customer Relationship</li>
                          <li>2. Loyalty Program</li>
                          <li>3. Design Retention Features </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="innerpagebranding">
          {/* custom */}
          <section class="mission pb-0 what-we">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                  <div class="section-header hiring_header">
                    <p>
                      Custom<br></br> Design <br></br> Process{" "}
                    </p>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                  <figure class="d-block d-sm-block d-md-none">
                    <div class="section-header hiring_header mt-3">
                      <h2>
                        Our Productivity is a Measure of Value that is able to
                        Impart with its Every Bit{" "}
                      </h2>
                    </div>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <div class="section-header hiring_header">
                      <h2>
                        Our Productivity is a Measure of Value <br></br>that is
                        able to Impart with its Every Bit{" "}
                      </h2>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </section>
          <section class="mission pt-0 what-we">
            <div className="container">
              <div class="ux-img-sec ux-img-sec1">
                <div class="container">
                  <ul class="ux-list">
                    <li class="ux-list1">
                      <h5>The Research</h5>
                      <p>
                        Our team then performs an analysis and puts together
                        everything that is required to begin.{" "}
                      </p>
                    </li>
                    <li class="ux-list2">
                      <h5>Present</h5>
                      <p>
                        {" "}
                        Present to you our idea and discuss it in detail. Take
                        notes of your ideas, thoughts, and suggestions.
                      </p>
                    </li>
                    <li class="ux-list3">
                      <h5>The Production </h5>
                      <p>Executing the final ideas on the revised version.</p>
                    </li>
                  </ul>
                  <img src={ux_path} class="ux-path" alt="custom-image" />
                  <div class="ux-row">
                    <div class="ux-row2 ux-row21">
                      <h5>The Brief </h5>
                      <p>
                        Give us your requirements in detail and tell us about
                        the goals you want to achieve.
                      </p>
                    </div>
                    <div class="ux-row2 ux-row22">
                      <h5>Ideation & Sketches</h5>
                      <p>
                        Brainstorming with the gathered data to finalize the
                        idea that aligns best with your brand.
                      </p>
                    </div>
                    <div class="ux-row2 ux-row23">
                      <h5>Revise</h5>
                      <p>
                        Make the necessary changes, improvise on the previous
                        version, and get it ready to go live.
                      </p>
                    </div>
                    <div class="ux-row2 ux-row24">
                      <h5>The Delivery</h5>
                      <p>Deliver it as expected within the set timelines.</p>
                    </div>
                    <div class="ux-row2 ux-row25">
                      <h5>Publish</h5>
                      <p>Go live. Take your audience by a warm surprise.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ux-img-sec ux-img-sec2 py-0">
                \
                <ul class="ux-list-2 mt-0">
                  <li>
                    <h4>01</h4>
                    <h5>The Brief</h5>
                    <p>
                      Give us your requirements in detail and tell us about the
                      goals you want to achieve.
                    </p>
                  </li>
                  <li>
                    <h4>02</h4>
                    <h5>The Research</h5>
                    <p>
                      Our team then performs an analysis and puts together
                      everything that is required to begin.{" "}
                    </p>
                  </li>
                  <li>
                    <h4>03</h4>
                    <h5>Ideation & Sketches</h5>
                    <p>
                      Brainstorming with the gathered data to finalize the idea
                      that aligns best with your brand.{" "}
                    </p>
                  </li>
                  <li>
                    <h4>04</h4>
                    <h5>Present</h5>
                    <p>
                      {" "}
                      Present to you our idea and discuss it in detail. Take
                      notes of your ideas, thoughts, and suggestions.
                    </p>
                  </li>
                  <li>
                    <h4>05</h4>
                    <h5>Revise</h5>
                    <p>
                      Make the necessary changes, improvise on the previous
                      version, and get it ready to go live.{" "}
                    </p>
                  </li>
                  <li>
                    <h4>06</h4>
                    <h5>The Production</h5>
                    <p>Executing the final ideas on the revised version. </p>
                  </li>
                  <li>
                    <h4>07</h4>
                    <h5>The Delivery</h5>
                    <p>Deliver it as expected within the set timelines. </p>
                  </li>
                  <li>
                    <h4>08</h4>
                    <h5>Publish</h5>
                    <p> Go live. Take your audience by a warm surprise.</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </section>
        <section class="learn_things " id="shop">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-3">
                <div class="section-header">
                  <p class="">Our Works</p>
                </div>
              </div>

              <div class="col-lg-9 col-md-9 col-xs-9 pr-0">
                <div className="row work_row customer-row project_rows">
                  {/* </OwlCarousel> */}
                  <div className="col-lg-8 col-md-8 col-xs-12 ">
                    <div className="card">
                      <OwlCarousel
                        items={1}
                        dots={true}
                        className="owl-theme product_images project_images"
                        loop
                        margin={40}
                      >
                        <div>
                          <img
                            src={Sreedhartruly}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div>
                          <img
                            src={Sreedhartruly1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                      </OwlCarousel>

                      <div className="card-body px-0 pb-0">
                        <h4>Sreedhar truly</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Personal Branding & identity{" "}
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            SREEDHER TRULY is a personal website that showcases
                            the work and credibility of the individual. We have
                            provided him the design that optimally tells his
                            story and skills.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card small_img">
                      <div>
                        <img src={Actly} className="img-fluid" alt="image" />
                      </div>

                      <div className="card-body px-0 pb-0">
                        <h4>actly</h4>
                        <div className="card-head">
                          <h6 className="work_head">
                            Branding & identity | Mobile App Designing | Product
                            UI, UX
                          </h6>
                        </div>
                        <div className="card-text mt-0 mb-0">
                          <p className="mb-0">
                            ACTLY is an online learning platform that contains a
                            lot of courses for film enthusiasts. Our design firm
                            developed and designed not only their Branding &
                            identity, but also product{" "}
                            <span className="text-uppercase">UI UX</span> along
                            with mobile app.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Design your Unique Idea Together. </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>Let’s Design your Unique Idea Together. </h2>
                    {/* <h2>New Technology. </h2> */}
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link" target="_blank">
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="arrow" />
                      </button>
                      </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default custom_design;
