import React, { Component } from "react";

import about_bnr from "../assets/img/home/about_bnr.svg";
import branding_process_img from "../assets/img/home/branding_process_img.svg";
import ux_process_img from "../assets/img/home/ux_process_img.svg";
import arrow_black from "../assets/img/home/arrow_black.svg";
import ux_path from "../assets/img/home/ux_path.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import process_bnr from "../assets/img/home/process_bnr.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import twitter from "../assets/img/home/twitter.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";

class process extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
         <Helmet >
          <title>UI and UX Design Services in India | Truly</title>
          <meta name="title" content="UI and UX Design Services in India | Truly" />
          <meta
            name="description"
            content="We offer compelling UI UX design services in India for immersive web experiences. Let us help you build interactive, compelling and intuitive brand designs."
          />
           <meta property="og:url" content="https://www.trulydesignfirm.com/process"/>
        </Helmet>
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
               
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img
                    src={process_bnr}
                    class="expert-bnr ourprocess_bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                <div className="d-flex align-items-end justify-content-between">
                <div class="banner_text banner_mob_text">
                  <h1>TRULY PROCESS</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Defining your Brand Story how you Want it</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>Defining your Brand Story how you Want it</h3>
                  </figure>
                  {/* <Link to="/contact"> */}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                    target="_blank"
                  >
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="arrow" />
                    </button>
                  </a>
                  {/* </Link> */}
                  {/* <Link to='/contact'><button class="primary_btn bnr_btn">contact us<img src={arrow_black} className=
      "arrow" alt="image"/></button></Link> */}
                </div>
                <figure class="d-none d-sm-none d-md-block">
                  <img src={process_bnr} class="expert-bnr ourprocess_bnr all_bnr_img" alt="image"/>
                </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* banner ends */}
        <section class="mission branding-sec pb-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    BRANDING<br></br>PROCESS{" "}
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header mt-3">
                    <h2>
                      A Brand is a Story that is Sold & We Make you a Brand{" "}
                    </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header">
                    <h2>
                      A Brand is a Story that is Sold <br></br>& We Make you a
                      Brand{" "}
                    </h2>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section class="mission branding-sec pt-0">
          <div className="container">
            <div class="row branding-row">
              <div class="col-lg-6 col-md-12 col-xs-12">
                <img
                  src={branding_process_img}
                  class="branding-process-img"
                  alt="branding-process-image"
                />
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12">
                <ul>
                  <li>
                    <h5>Perception</h5>
                    <p>
                      {" "}
                      gathering insights on the consumers’ feelings - negative
                      and positive, and thoughts that follow when they hear and
                      see the brand.
                    </p>
                  </li>
                  <li>
                    <h5>Messaging</h5>
                    <p>
                      putting together the right tone, context, and words in a
                      way that your customers most relate to and motivate them
                      to use your product.
                    </p>
                  </li>
                  <li>
                    <h5>Tone of Voice</h5>
                    <p>
                      conveying your brand’s personality to consumers through
                      the right choice of words and an acceptable tone of voice.
                    </p>
                  </li>
                  <li>
                    <h5>Positioning</h5>
                    <p>
                      helping consumers register your brand as a certain entity
                      that they can most relate to
                    </p>
                  </li>
                  <li>
                    <h5>Story</h5>
                    <p>
                      narrating the history of how, why and when your brand came
                      to be to convey your motto to your audience.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* ux */}
        <section class="mission pb-0">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>
                    <span className="text-uppercase">UX</span> Process{" "}
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header mt-3">
                    <h2>
                      “Good Design is About Process, Not Product” - Jared
                      Sinclair{" "}
                    </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header">
                    <h2>
                      “Good Design is About Process, <br></br>Not Product” -
                      Jared Sinclair{" "}
                    </h2>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section class="mission pt-0">
          <div className="container">
            <div class="row branding-row mt-0 ux_row">
              <div class="col-lg-6 col-md-12 col-xs-12">
                <figure class="d-none d-sm-none d-md-block">
                  <img
                    src={ux_process_img}
                    class="branding-process-img"
                    alt="ux-process-image.svg"
                  />
                </figure>
              </div>
              <div class="col-lg-6 col-md-12 col-xs-12 pl-0">
                <ul class="pl-0">
                  <li>
                    <h5>Release, Shipped Product, Beta Designs</h5>
                    <p>
                      gaining insights into the{" "}
                      <span className="text-uppercase">UX</span> of your product
                      along with a deeper understanding of your business goals
                      through beta testing to test if your goals align with the
                      needs of the customers.
                    </p>
                  </li>
                  <li>
                    <h5>Visual Design, Interaction Design</h5>
                    <p>
                      designing the interface and paying attention to detail by
                      keeping the best interest of users’ in mind while also
                      leaving room for improvisations. All this achieved by
                      taking into consideration different principles, colors,
                      properties, and other design variables.
                    </p>
                  </li>
                  <li>
                    <h5>Process Architecture</h5>
                    <p>
                      developing a visual blueprint of the product based on the
                      business’ goals and hierarchy. Including the levels of
                      details in the information architecture as per
                      requirements.
                    </p>
                  </li>
                  <li>
                    <h5>Research + Strategy & Conceptual Design</h5>
                    <p>
                      starting with thorough market research on the product
                      followed by building a strategy that best works for your
                      brand. Next, marking broad outlines of function and form
                      such as designing interactions, processes, and experiences
                      which are in line with the strategy.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* custom */}
        <section class="mission what-we">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header">
                  <p>
                    Custom<br></br> Design <br></br> Process{" "}
                  </p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>
                      Our Productivity is a Measure of Value that is able to
                      Impart with its Every Bit{" "}
                    </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Our Productivity is a Measure of Value <br></br>that is
                      able to Impart with its Every Bit{" "}
                    </h2>
                  </div>
                </figure>
              </div>
            </div>
          </div>

          <div className="container">
            <div class="ux-img-sec ux-img-sec1">
              <div class="container">
                <ul class="ux-list">
                  <li class="ux-list1">
                    <h5>The Research</h5>
                    <p>
                      Our team then performs an analysis and puts together
                      everything that is required to begin.{" "}
                    </p>
                  </li>
                  <li class="ux-list2">
                    <h5>Present</h5>
                    <p>
                      {" "}
                      present to you our idea and discuss it in detail. Take
                      notes of your ideas, thoughts, and suggestions.
                    </p>
                  </li>
                  <li class="ux-list3">
                    <h5>The Production </h5>
                    <p>executing the final ideas on the revised version.</p>
                  </li>
                </ul>
                <img src={ux_path} class="ux-path" alt="custom-image" />
                <div class="ux-row">
                  <div class="ux-row2 ux-row21">
                    <h5>The Brief </h5>
                    <p>
                      give us your requirements in detail and tell us about the
                      goals you want to achieve.
                    </p>
                  </div>
                  <div class="ux-row2 ux-row22">
                    <h5>Ideation & Sketches</h5>
                    <p>
                      brainstorming with the gathered data to finalize the idea
                      that aligns best with your brand.
                    </p>
                  </div>
                  <div class="ux-row2 ux-row23">
                    <h5>Revise</h5>
                    <p>
                      make the necessary changes, improvise on the previous
                      version, and get it ready to go live.
                    </p>
                  </div>
                  <div class="ux-row2 ux-row24">
                    <h5>The Delivery</h5>
                    <p>deliver it as expected within the set timelines.</p>
                  </div>
                  <div class="ux-row2 ux-row25">
                    <h5>Publish</h5>
                    <p>go live. Take your audience by a warm surprise.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="ux-img-sec ux-img-sec2 py-0">
              
              <ul class="ux-list-2 mt-0">
                <li>
                  <h4>01</h4>
                  <h5>The Brief</h5>
                  <p>
                    give us your requirements in detail and tell us about the
                    goals you want to achieve.
                  </p>
                </li>
                <li>
                  <h4>02</h4>
                  <h5>The Research</h5>
                  <p>
                    our team then performs an analysis and puts together
                    everything that is required to begin.{" "}
                  </p>
                </li>
                <li>
                  <h4>03</h4>
                  <h5>Ideation & Sketches</h5>
                  <p>
                    brainstorming with the gathered data to finalize the idea
                    that aligns best with your brand.{" "}
                  </p>
                </li>
                <li>
                  <h4>04</h4>
                  <h5>Present</h5>
                  <p>
                    {" "}
                    present to you our idea and discuss it in detail. Take notes
                    of your ideas, thoughts, and suggestions.
                  </p>
                </li>
                <li>
                  <h4>05</h4>
                  <h5>Revise</h5>
                  <p>
                    make the necessary changes, improvise on the previous
                    version, and get it ready to go live.{" "}
                  </p>
                </li>
                <li>
                  <h4>06</h4>
                  <h5>The Production</h5>
                  <p>executing the final ideas on the revised version. </p>
                </li>
                <li>
                  <h4>07</h4>
                  <h5>The Delivery</h5>
                  <p>deliver it as expected within the set timelines. </p>
                </li>
                <li>
                  <h4>08</h4>
                  <h5>Publish</h5>
                  <p> go live. Take your audience by a warm surprise.</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>
                      We Share the Same Goal - Creating Memorable User
                      Experiences{" "}
                    </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      We Share the Same Soal - <br></br>Creating Memorable User
                      Experiences{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                      target="_blank"
                    >
                      {/* <Link to="/contact"> */}
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" />
                      </button>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default process;
