import React, { Component } from "react";

import testimonial_bnr from "../assets/img/home/testimonial_bnr.svg";
import cust1 from "../assets/img/home/cust1.png";
import cust2 from "../assets/img/home/cust2.png";
import cust3 from "../assets/img/home/cust3.png";
import arrow_black from "../assets/img/home/arrow_black.svg";
import cust4 from "../assets/img/home/cust4.png";
import cust5 from "../assets/img/home/cust5.png";
import cust6 from "../assets/img/home/cust6.png";
import quote from "../assets/img/home/quote.svg";
import behance_logo from "../assets/img/home/behance_logo.svg";
import youtube from "../assets/img/home/youtube.svg";
import facebook from "../assets/img/home/facebook.svg";
import instagram from "../assets/img/home/instagram.svg";
import quote1 from "../assets/img/home/quote1.svg";
import { HashLink as Link } from "react-router-hash-link";
import * as $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class testimonials extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="animate-sec">
        {/* banner starts */}
        <section class="banner">
          <div class="container banner_container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-xs-12">
                <figure class="d-none d-sm-none d-md-block">
                  <img src={testimonial_bnr} class="expert-bnr all_bnr_img" alt="image"/>
                </figure>
              </div>
              <div class="col-lg-9 col-md-6 col-xs-12">
                <figure class="d-block d-sm-block d-md-none">
                  {" "}
                  <img
                    src={testimonial_bnr}
                    class="expert-bnr all_bnr_img"
                    alt="image"
                  />
                </figure>
                <div class="banner_text banner_mob_text">
                  <h1>TESTIMONIALS</h1>
                  <figure class="d-block d-sm-block d-md-none">
                    <h3>Our Customers Say We are Reliable</h3>
                  </figure>
                  <figure class="d-none d-sm-none d-md-block">
                    <h3>
                      Our Customers Say
                      <br></br>We are Reliable
                    </h3>
                  </figure>
                  {/* <Link to="/contact"> */}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                    target="_blank"
                  >
                    <button class="primary_btn bnr_btn">
                      contact us
                      <img src={arrow_black} className="arrow" alt="arrow" />
                    </button>
                  </a>
                  {/* </Link> */}
                  {/* <Link to='/contact'><button class="primary_btn bnr_btn">contact us<img src={arrow_black} className=
      "arrow" alt="image"/></button></Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mission">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header">
                  <p>Testimonials</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0 about_col">
                <div class="section-header  team-header">
                  <h2>
                    Reviews from our Happy Clients are What Drives us to Give
                    our Best
                  </h2>
                </div>

                <div class="row customer-row test_row black_cards">
                  <div className="column">
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>Nuacem</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            I approached Truly with a basic “feel” I wanted in a
                            website. They helped our new site here at Nuacem.
                            Truly services are bargain when you weigh the cost
                            versus the product you receive. They were responsive
                            and persistent in contacting us to complete the
                            project . Thanks again!
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust1}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Vamsi Krishna</h5>
                            <p>Founder & CEO</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>IDEEO</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            Truly is definitely a great designer partner. They
                            set us up for success. The design team far exceeded
                            my expectations, Scope was structured and clear on
                            every front...
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                          {/* <p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust4}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Sridhar</h5>
                            <p>Foundr & Design head</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>Starfire</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            My new site created by Truly just launched. They’ve
                            done a great job of creating exactly what I wanted.
                            In addition, they add a lot of effects to a design
                            which makes them stand out in comparison with the
                            competition.
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust2}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Shweta</h5>
                            <p>Founder</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>HEPC</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            "The logo is a head-turner and has regenerated our
                            company’s image". Truly delivered our logo design
                            promptly, including the versions we requested.
                            Highly recommended!
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust5}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Chandra Sekhar</h5>
                            <p>Founder</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>Body workout</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            I knew I was going to enjoy working with Sridhar and
                            the team of energetic and creative professionals.I
                            am really happy with what they have delivered. Easy
                            to work with and very responsive. They met all of my
                            expectations and had a lot of insight regarding
                            marketing tools I didn’t know existed. BodyWorkout
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust3}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Akheel</h5>
                            <p>Founder</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card cust-card active">
                      <div class="card-body cust-card">
                        <div class="card-head">
                          {/* <!-- <i class="fa fa-quote1-left" aria-hidden="true"></i> --> */}
                          <img src={quote1} class="fa" alt="quote1" />
                          <h6>SSM Institute</h6>
                        </div>
                        <div class="card-text">
                          <p class="testmonial-txt">
                            Truly constructed a new website for us that is
                            perfect and they did it quickly and efficiently .
                            They also created a mobile friendly format for cell
                            phones...
                          </p>
                          {/* <!--<p><a href="http://trulytechsolutions.com/testimonials.html#testimonial-sec">Read more</a></p>--> */}
                        </div>
                        <div class="card-footer1">
                          <img
                            src={cust6}
                            class="cust-img"
                            alt="truly-client-vamsi"
                          />
                          <div class="">
                            <h5>Indarjith Mathivanan</h5>
                            <p>Founder & CEO</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hiring */}
        <section class="mission hiring" id="hiredesigner">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 pl-0">
                <div class="section-header hiring_header text_white">
                  <p>Let’s talk!</p>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-xs-12 pl-0">
                <figure class="d-block d-sm-block d-md-none">
                  <div class="section-header hiring_header mt-3">
                    <h2>Let’s Start a New Project Together </h2>
                  </div>
                </figure>
                <figure class="d-none d-sm-none d-md-block">
                  <div class="section-header hiring_header">
                    <h2>
                      Let’s Start a New Project <br></br>Together{" "}
                    </h2>
                  </div>
                </figure>

                <div class="mission_paras hiring_para contact_para">
                  <p className="mb-0">
                    Feel free to ask us about our team, technology, process and
                    we’re happy to answer all your questions.
                  </p>
                  <div className="d-flex all_btns">
                    {/* <Link to="/contact"> */}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfCUgo5s59NGaGNwHlV9FCZylD-K0MYLbKBElebsLmN2Sm8Dg/viewform?usp=sf_link"
                      target="_blank"
                    >
                      <button class="primary_btn calltoaction">
                        get in touch
                        <img src={arrow_black} className="arrow" alt="image" />
                      </button>
                    </a>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default testimonials;
